import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';

const ConfettiComponent = ({ showConfetti, setShowConfetti }) => {
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });
  const [confettiShouldStop, setConfettiShouldStop] = useState(false);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  useEffect(() => {
    if (showConfetti) {
      // Reset the stop flag when confetti starts
      setConfettiShouldStop(false);

      // Set a timer to start stopping the confetti
      const timer = setTimeout(() => {
        setConfettiShouldStop(true);
      }, 3000); // Adjust this time to control when confetti starts to stop

      return () => clearTimeout(timer);
    }
  }, [showConfetti]);

  const handleConfettiComplete = () => {
    setShowConfetti(false);
  };

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }}>
      {showConfetti && (
        <Confetti
          width={windowDimensions.width}
          height={windowDimensions.height}
          numberOfPieces={200}
          gravity={0.2}
          initialVelocityY={0}
          wind={0}
          recycle={!confettiShouldStop}
          run={showConfetti}
          onConfettiComplete={handleConfettiComplete}
        />
      )}
    </div>
  );
};

export default ConfettiComponent;