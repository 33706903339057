// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCKn6Lu4TALSgRPEl69PQJyVOLsLlqcRB8",
  authDomain: "samplegenerator-306d7.firebaseapp.com",
  projectId: "samplegenerator-306d7",
  storageBucket: "samplegenerator-306d7.appspot.com",
  messagingSenderId: "337231970724",
  appId: "337231970724:web:9ad9352c357b872ea00131"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const storage = getStorage(app);

export const signUp = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    
    await setDoc(doc(db, 'users', user.uid), {
      email: user.email,
      credits: 5,
      createdAt: new Date(),
      generatedTracks: []
    });

    return user;
  } catch (error) {
    console.error("Error in signUp:", error);
    throw error;
  }
};

export const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error("Error in signIn:", error);
    throw error;
  }
};

const getUserCredits = async (userId) => {
  const userRef = doc(db, "users", userId);
  const userSnap = await getDoc(userRef);
  if (userSnap.exists()) {
    return userSnap.data().credits;
  } else {
    console.log("No such user!");
    return null;
  }
};

export const updateUserCredits = async (userId, amount) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      credits: increment(amount)
    });
  } catch (error) {
    console.error("Error in updateUserCredits:", error);
    if (error.code === 'permission-denied') {
      console.error("Permission denied. Check Firestore rules.");
    }
    throw error;
  }
};

export const purchaseCredits = async (userId, packageDetails) => {
  try {
    // Here you would typically integrate with a payment system
    // For this example, we'll just update the credits
    await updateUserCredits(userId, packageDetails.credits);
  } catch (error) {
    console.error("Error in purchaseCredits:", error);
    throw error;
  }
};

export { auth, db, storage, googleProvider, getUserCredits };