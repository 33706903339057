import React, { useEffect, useState, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import Hover from "wavesurfer.js/dist/plugins/hover.esm.js";

import { Play, Pause, Volume2, VolumeX } from "lucide-react";
import styled from "styled-components";

const AudioControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const PlayButton = styled.button`
  background: none;
  border: none;
  color: #f3b718;
  cursor: pointer;
  font-size: 24px;
`;

const Timer = styled.span`
  font-size: 18px;
  color: #ffffff;
`;

const VolumeControl = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;

const VolumeSlider = styled.input`
  -webkit-appearance: none;
  width: 100px;
  height: 4px;
  border-radius: 2px;
  background: #4a4a4a;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  margin-left: 8px;
  rotate: -90deg;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #f3b718;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #f3b718;
    cursor: pointer;
  }
`;

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = (seconds % 60).toFixed(2);
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.padStart(
    5,
    "0"
  )}`;
};

const TrackWaveform = ({ trackUrls, setIsLoaded, stem , duration}) => {
  const [wave1Volume, setWave1Volume] = useState(1);
  const [wave2Volume, setWave2Volume] = useState(1);
  const [wave1Muted, setWave1Muted] = useState(false);
  const [wave2Muted, setWave2Muted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [wave1Loaded, setWave1Loaded] = useState(false);
  const [wave2Loaded, setWave2Loaded] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  let wavesurfer1 = useRef(null);
  let wavesurfer2 = useRef(null);

  useEffect(() => {
    if ((wave1Loaded, wave2Loaded)) {
      setIsLoaded(true);
    }
  }, [wave1Loaded, wave2Loaded]);

  useEffect(() => {
    if (!trackUrls.length) return;

    wavesurfer1.current = WaveSurfer.create({
      container: "#waveform1",
      waveColor: "#888888",
      progressColor: "#f3b718",
      dragToSeek: true,
      url: trackUrls[0],
      plugins: [
        Hover.create({
          lineColor: "#ff0000",
          lineWidth: 2,
          labelBackground: "#555",
          labelColor: "#fff",
          labelSize: "11px",
        }),
      ],
    });
    wavesurfer2.current = WaveSurfer.create({
      container: "#waveform2",
      waveColor: "#888888",
      progressColor: "#f3b718",
      dragToSeek: true,
      url: trackUrls[1],
      plugins: [
        Hover.create({
          lineColor: "#ff0000",
          lineWidth: 2,
          labelBackground: "#555",
          labelColor: "#fff",
          labelSize: "11px",
        }),
      ],
    });

    const updateCurrentTime = () => {
      const time1 = wavesurfer1.current.getCurrentTime();
      const time2 = wavesurfer2.current.getCurrentTime();
      setCurrentTime(Math.min(time1, time2));
    };

    wavesurfer1.current.on("audioprocess", updateCurrentTime);
    wavesurfer2.current.on("audioprocess", updateCurrentTime);

    wavesurfer1.current.on("ready", () => {
      console.log("loaded 1");
      setWave1Loaded(true);
    });

    wavesurfer1.current.on("error", () => {
      setWave1Loaded(false);
    });

    wavesurfer1.current.on("click", () => {
      wavesurfer1.current.play();
    });

    wavesurfer2.current.on("ready", () => {
      console.log("loaded 2");
      setWave2Loaded(true);
    });

    wavesurfer2.current.on("error", () => {
      setWave2Loaded(false);
    });

    wavesurfer2.current.on("click", () => {
      wavesurfer2.current.play();
    });

    // Synchronize 'play' event
    wavesurfer1.current.on("play", () => {
      wavesurfer2.current.play();
      if (!isPlaying) {
        setIsPlaying(true);
      }
    });
    wavesurfer2.current.on("play", () => {
      wavesurfer1.current.play();
      if (!isPlaying) {
        setIsPlaying(true);
      }
    });

    // Synchronize 'pause' event
    wavesurfer1.current.on("pause", () => {
      wavesurfer2.current.pause();
      if (isPlaying) {
        setIsPlaying(false);
      }
    });
    wavesurfer2.current.on("pause", () => {
      wavesurfer1.current.pause();
      if (isPlaying) {
        setIsPlaying(false);
      }
    });

    // Synchronize 'finish' event
    wavesurfer1.current.on("finish", () => {
      wavesurfer2.current.stop();
      wavesurfer2.current.seekTo(0);
      if (isPlaying) {
        setIsPlaying(false);
      }
    });
    wavesurfer2.current.on("finish", () => {
      wavesurfer1.current.stop();
      wavesurfer1.current.seekTo(0);
      if (isPlaying) {
        setIsPlaying(false);
      }
    });

    // Synchronize 'seek' event
    wavesurfer1.current.on("seek", (progress) => {
      wavesurfer2.current.seekTo(progress);
    });
    wavesurfer2.current.on("seek", (progress) => {
      wavesurfer1.current.seekTo(progress);
    });

    // Synchronize 'interaction' event
    wavesurfer1.current.on("interaction", () => {
      wavesurfer2.current.seekTo(
        wavesurfer1.current.getCurrentTime() / wavesurfer1.current.getDuration()
      );
    });
    wavesurfer2.current.on("interaction", () => {
      wavesurfer1.current.seekTo(
        wavesurfer2.current.getCurrentTime() / wavesurfer2.current.getDuration()
      );
    });

    // Synchronize 'click' event
    wavesurfer1.current.on("click", (progress) => {
      wavesurfer2.current.seekTo(progress);
    });
    wavesurfer2.current.on("click", (progress) => {
      wavesurfer1.current.seekTo(progress);
    });

    // Synchronize 'drag' event
    wavesurfer1.current.on("drag", (progress) => {
      wavesurfer2.current.seekTo(progress);
    });
    wavesurfer2.current.on("drag", (progress) => {
      wavesurfer1.current.seekTo(progress);
    });

    // Synchronize 'scroll' event
    wavesurfer1.current.on("scroll", (progress) => {
      wavesurfer2.current.seekTo(progress);
    });
    wavesurfer2.current.on("scroll", (progress) => {
      wavesurfer1.current.seekTo(progress);
    });

    // Clean up WaveSurfer on unmount
    return () => {
      wavesurfer1.current.destroy();
      wavesurfer2.current.destroy();
    };
  }, [trackUrls]);

  const handlePlayPause = () => {
    if (isPlaying) {
      wavesurfer1.current.pause();
      wavesurfer2.current.pause();
      setIsPlaying(false);
    } else {
      wavesurfer1.current.play();
      wavesurfer2.current.play();
      setIsPlaying(true);
    }
  };

  const handleWave1Volume = (e) => {
    const volume = parseFloat(e.target.value);
    setWave1Volume(volume);
    wavesurfer1.current.setVolume(volume);
    if (wave1Muted) {
      setWave1Muted(false);
    }
  };

  const handleWave2Volume = (e) => {
    const volume = parseFloat(e.target.value);
    setWave2Volume(volume);
    wavesurfer2.current.setVolume(volume);
    if (wave2Muted) {
      setWave2Muted(false);
    }
  };

  const handleWave1Mute = () => {
    if (wave1Muted) {
      wavesurfer1.current.setVolume(wave1Volume);
      setWave1Muted(false);
    } else {
      wavesurfer1.current.setVolume(0);
      setWave1Muted(true);
    }
  };
  const handleWave2Mute = () => {
    if (wave2Muted) {
      wavesurfer2.current.setVolume(wave2Volume);
      setWave2Muted(false);
    } else {
      wavesurfer2.current.setVolume(0);
      setWave2Muted(true);
    }
  };

  return (
    <>
      <div className="flex">
        <div id="waveform1" className="w-[85vw] max-w-[38rem]">
          <p className="my-2 uppercase">{stem}</p>
          {/* Waveform 1 will be rendered here */}
        </div>
        <VolumeControl>
          <div
            className="flex justify-center relative bottom-[3.5rem] ml-[7px]"
            onClick={handleWave1Mute}
          >
            {wave1Muted ? (
              <VolumeX size={20} color="#888888" />
            ) : (
              <Volume2 size={20} color="#f3b718" />
            )}
          </div>
          <VolumeSlider
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={wave1Volume}
            onChange={handleWave1Volume}
          />
        </VolumeControl>
      </div>
      <div className="flex">
        <div id="waveform2" className="w-[85vw] max-w-[38rem]">
          <p className="my-2 uppercase"> Without {stem}</p>
          {/* Waveform 2 will be rendered here */}
        </div>
        <VolumeControl>
          <div
            className="flex justify-center relative bottom-[3.5rem] ml-[7px]"
            onClick={handleWave2Mute}
          >
            {wave2Muted ? (
              <VolumeX size={20} color="#888888" />
            ) : (
              <Volume2 size={20} color="#f3b718" />
            )}
          </div>
          <VolumeSlider
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={wave2Volume}
            onChange={handleWave2Volume}
          />
        </VolumeControl>
      </div>
      <div className="mt-10">
        <AudioControls>
          <PlayButton onClick={handlePlayPause}>
            {isPlaying ? <Pause size={24} /> : <Play size={24} />}
          </PlayButton>
          <Timer>
            {formatTime(currentTime)}/{formatTime(duration)}
          </Timer>
        </AudioControls>
      </div>
    </>
  );
};

export default TrackWaveform;
