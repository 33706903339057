import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../firebase";
import { Menu, LogIn, LogOut, User } from "react-feather";
import styled, { keyframes } from "styled-components";

const NavbarContainer = styled.nav`
  background-color: #000000;
  color: #ffffff;
  font-family: "Roboto", "Arial", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  max-width: 1280px;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const NavbarBrand = styled(Link)`
  img {
    height: 40px;
    width: auto;
    object-fit: contain;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const TelegramLink = styled.a`
  display: flex;
  align-items: center;
  color: #f3b718;
  text-decoration: none;

  img {
    height: 24px;
    width: auto;
    transition: transform 0.3s ease;
  }

  &:hover img {
    animation: ${pulse} 0.5s ease-in-out;
  }
`;

const BackgroundSelect = styled.select`
  background-color: #f3b718;
  color: #000000;
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
`;

const Credits = styled.div`
  color: #f3b718;
  font-size: 0.9rem;
`;

const SubscriptionLink = styled(Link)`
  color: #f3b718;
  text-decoration: none;
  font-size: 0.9rem;
  &:hover {
    text-decoration: underline;
  }
`;

const UserMenuButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  color: #f3b718;
  cursor: pointer;
  font-size: 0.9rem;
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 2px;
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 0.5rem 0;
  z-index: 1001;
`;

const DropdownItem = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  border: none;
  color: #f3b718;
  cursor: pointer;
  text-align: left;
  font-size: 0.9rem;
  &:hover {
    background-color: #333;
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: #f3b718;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileMenu = styled.div`
  display: none;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: #1a1a1a;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1001;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const MobileMenuItem = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  color: #f3b718;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.5rem 0;
`;

const NavbarItems = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }
`;

const TermsButton = styled.button`
  background-color: transparent;
  border: none;
  color: #f3b718;
  border: 1px solid #f3b718;
  padding: 0.2rem 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
const AdminButton = styled.button`
  background-color: transparent;
  border: none;
  color: #f3b718;
  border: 1px solid #f3b718;
  padding: 0.2rem 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
  margin-left: 20px;

  &:hover {
    text-decoration: underline;
    background-color: #f3b718;
    color: black;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #f3b718;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const ReferButton = styled(Link)`
  color: #f3b718;
  font-size: 15px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Navbar = ({
  onBackgroundChange,
  credits,
  isLoggedIn,
  setIsLoggedIn,
  username,
  setUsername,
  setShowTCModal,
  isAdmin,
  customerId,
  setIsAlert,
  setAlertContent,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [billingLoading, setBillingLoading] = useState(false);
  const dropdownRef = useRef(null);
  const fileInputRef = useRef(null);
  const [background, setBackground] = useState("/backgrounds/Sunset.png");
  const [isCustomBackground, setIsCustomBackground] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const backgrounds = [
    { name: "Sunset", value: "/backgrounds/Sunset.png" },
    { name: "Moonlit", value: "/backgrounds/Moonlit.png" },
    { name: "Starry", value: "/backgrounds/Starry.png" },
    { name: "Lantern", value: "/backgrounds/Lantern.png" },
    { name: "Stormy", value: "/backgrounds/Stormy.png" },
    { name: "Drive", value: "/backgrounds/Drive.png" },
  ];

  useEffect(() => {
    const bg = localStorage.getItem("background");
    if (bg) {
      setBackground(bg);
      setIsCustomBackground(bg.startsWith("data:"));
    }
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      setIsLoggedIn(true);
      setUsername(result.user.displayName || result.user.email);
      navigate("/");
    } catch (error) {
      console.error("Error signing in: ", error);
    }
  };

  const handleSignup = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      setIsLoggedIn(true);
      setUsername(result.user.displayName || result.user.email);
      navigate("/");
    } catch (error) {
      console.error("Error signing up: ", error);
    }
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        setIsLoggedIn(false);
        setUsername("");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  const handleBackgroundChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "custom") {
      fileInputRef.current.click();
    } else {
      localStorage.setItem("background", selectedValue);
      setBackground(selectedValue);
      setIsCustomBackground(false);
      onBackgroundChange(selectedValue);
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const customBackground = event.target.result;
        localStorage.setItem("background", customBackground);
        setBackground(customBackground);
        setIsCustomBackground(true);
        onBackgroundChange(customBackground);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBilling = async () => {
    if (customerId === null) {
      setIsAlert(true);
      setAlertContent({
        title: "Not Found",
        message:
          "You don't have a active billing period. Please buy a subscription plan first",
      });
      navigate("/subscription");
    }
    setBillingLoading(true);
    const response = await fetch(
      "https://audio-gen-server.vercel.app/api/create-billing-session",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customerId,
        }),
      }
    );

    const { url: sessionUrl } = await response.json();

    if (!sessionUrl) {
      setBillingLoading(false);
      setIsAlert(true);
      setAlertContent({
        title: "Not Found",
        message:
          "You don't have a active billing period. Please buy a subscription plan.",
      });
      navigate("/subscription");
      return;
    }

    window.location.href = sessionUrl;
  };

  return (
    <>
      <NavbarContainer>
        <NavbarContent>
          <LogoContainer>
            <NavbarBrand to="/">
              <img src="/SampleGeneratorBETA.png" alt="SampleGenerator.xyz" />
            </NavbarBrand>
            <TelegramLink
              href="https://t.me/smplgenprtl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/telegram.png" alt="Telegram" />
            </TelegramLink>
          </LogoContainer>

          <NavbarItems>
            {isLoggedIn && (
              <>
                <BackgroundSelect
                  onChange={handleBackgroundChange}
                  value={isCustomBackground ? "custom" : background}
                >
                  {backgrounds.map((bg) => (
                    <option key={bg.value} value={bg.value}>
                      {bg.name}
                    </option>
                  ))}
                  <option value="custom">Choose from PC</option>
                </BackgroundSelect>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </>
            )}

            {isLoggedIn && <Credits>Credits: {credits}</Credits>}
            {isLoggedIn && (
              <SubscriptionLink
                className="SubscriptionButton"
                to="/subscription"
              >
                Manage Subscription
              </SubscriptionLink>
            )}

            <div style={{ position: "relative" }}>
              <UserMenuButton
                className="UserMenu"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                {isLoggedIn ? (
                  <>
                    <User size={20} />
                    {username}
                  </>
                ) : (
                  <>
                    <LogIn size={20} />
                    Login / Signup
                  </>
                )}
              </UserMenuButton>

              {showDropdown && (
                <DropdownMenu ref={dropdownRef} className="dropdownMenu">
                  {isLoggedIn ? (
                    <>
                      <DropdownItem onClick={handleLogout}>
                        <LogOut size={16} />
                        Logout
                      </DropdownItem>
                      <DropdownItem
                        className="BillingButton"
                        onClick={handleBilling}
                      >
                        Billing
                      </DropdownItem>
                    </>
                  ) : (
                    <>
                      <DropdownItem onClick={handleLogin}>
                        <LogIn size={16} />
                        Login
                      </DropdownItem>
                      <DropdownItem onClick={handleSignup}>
                        <User size={16} />
                        Signup
                      </DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              )}
            </div>
            {isLoggedIn && (
              <div>
                <ReferButton to="/refer">Refer a Friend</ReferButton>
              </div>
            )}
            <TermsButton onClick={() => setShowTCModal(true)}>T&C</TermsButton>
            {isAdmin && (
              <AdminButton
                onClick={() => {
                  location.pathname === "/admin"
                    ? (window.location.href = "/")
                    : (window.location.href = "/admin");
                }}
              >
                {location.pathname === "/admin" ? "Go Back" : "Admin Panel"}
              </AdminButton>
            )}
          </NavbarItems>

          <MobileMenuButton onClick={() => setShowMobileMenu(!showMobileMenu)}>
            <Menu size={24} />
          </MobileMenuButton>
        </NavbarContent>

        {showMobileMenu && (
          <MobileMenu>
            {isLoggedIn ? (
              <>
                <BackgroundSelect
                  onChange={(e) => {
                    handleBackgroundChange(e);
                    setShowMobileMenu(false);
                  }}
                  value={isCustomBackground ? "custom" : background}
                >
                  {backgrounds.map((bg) => (
                    <option key={bg.value} value={bg.value}>
                      {bg.name}
                    </option>
                  ))}
                  <option value="custom">Choose from PC</option>
                </BackgroundSelect>
                <Credits>Credits: {credits}</Credits>
                <SubscriptionLink
                  to="/subscription"
                  onClick={() => setShowMobileMenu(false)}
                >
                  Manage Subscription
                </SubscriptionLink>
                <MobileMenuItem
                  onClick={() => {
                    handleLogout();
                    setShowMobileMenu(false);
                  }}
                >
                  <LogOut size={16} />
                  Logout
                </MobileMenuItem>
                <MobileMenuItem
                  onClick={() => {
                    handleBilling();
                    setShowMobileMenu(false);
                  }}
                >
                  <LogOut size={16} />
                  Billing
                </MobileMenuItem>
              </>
            ) : (
              <>
                <MobileMenuItem
                  onClick={() => {
                    handleLogin();
                    setShowMobileMenu(false);
                  }}
                >
                  <LogIn size={16} />
                  Login
                </MobileMenuItem>
                <MobileMenuItem
                  onClick={() => {
                    handleSignup();
                    setShowMobileMenu(false);
                  }}
                >
                  <User size={16} />
                  Signup
                </MobileMenuItem>
              </>
            )}
            {isLoggedIn && (
              <div>
                <ReferButton to="/refer">Refer a Friend</ReferButton>
              </div>
            )}
            <MobileMenuItem
              onClick={() => {
                setShowTCModal(true);
                setShowMobileMenu(false);
              }}
            >
              <span style={{ color: "#f3b718" }}>T&C</span>
            </MobileMenuItem>
            {isAdmin && (
              <AdminButton
                onClick={() => {
                  location.pathname === "/admin"
                    ? navigate("/")
                    : navigate("/admin");
                  setShowMobileMenu(false);
                }}
              >
                {location.pathname === "/admin" ? "Go Back" : "Admin Panel"}
              </AdminButton>
            )}
          </MobileMenu>
        )}
      </NavbarContainer>
      {billingLoading && (
        <LoadingOverlay className="flex flex-col gap-5">
          <LoadingSpinner />
          <p className="text-black bg-[#f3b718] p-1">
            Redirecting To Billing Page..
          </p>
        </LoadingOverlay>
      )}
    </>
  );
};

export default Navbar;
