import React, { useState, useEffect, useRef , useCallback} from "react";
import debounce from "lodash/debounce";
import styled, { keyframes, css } from "styled-components";
import { Play, Pause, Download, Layers, Loader, Share2, X , AudioLines } from "lucide-react";
import { Tooltip } from "react-tooltip";
import localforage from "localforage";
import { shortenUrl } from "../services/bitlyService";

const Container = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 20px;
  background: rgba(10, 10, 10, 0.9);
  border-radius: 0 0 15px 15px;
  color: #e0e0e0;
  font-family: "Roboto", "Arial", sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(243, 183, 24, 0.5) rgba(10, 10, 10, 0.1);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(10, 10, 10, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(243, 183, 24, 0.5);
    border-radius: 3px;
  }

  @media (min-width: 768px) {
    position: fixed;
    top: 80px;
    right: 20px;
    width: calc(50% - 40px);
    max-height: calc(100vh - 180px);
    border-radius: 15px;
  }

  @media (max-width: 767px) {
    margin: 0 auto 10px;
  }
`;

const Title = styled.h2`
  color: #f3b718;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 20px;
    margin-bottom: 15px;
  }
`;

const TrackList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 767px) {
    gap: 10px;
  }
`;

const TrackItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  background-color: ${(props) =>
    props.$isPlaying ? "rgba(243, 183, 24, 0.1)" : "rgba(30, 30, 30, 0.8)"};
  border-radius: 8px;
  transition: background-color 0.3s ease;

  @media (max-width: 767px) {
    padding: 10px;
  }
`;

const TrackImageWrapper = styled.div`
  position: relative;
  margin-right: 15px;
  width: 60px;
  height: 60px;
  flex-shrink: 0;

  @media (max-width: 767px) {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
`;

const TrackImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
`;

const NewTrackDot = styled.span`
  background: #f3b718;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: -4px;
  top: -4px;
`;

const TrackInfo = styled.div`
  width: 12rem;
  flex-grow: 1;
  min-width: 0;
`;

const TrackTitle = styled.div`
  font-weight: bold;
  font-size: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 767px) {
    font-size: 0.9em;
  }
`;

const TrackGenre = styled.div`
  font-size: 0.9em;
  color: #f3b718;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 767px) {
    font-size: 0.8em;
  }
`;

const TrackControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 185px;
  margin-left: auto;

  @media (max-width: 767px) {
    max-width: fit-content;
    justify-content: flex-end;
  }
`;

const iconAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const ControlButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => (props.disabled ? "#666" : "#f3b718")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: opacity 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover:not(:disabled) {
    color: #f8d66d;
    animation: ${iconAnimation} 0.5s ease infinite;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const ShareButton = styled(ControlButton)`

  display:block;
`;

const LoadingPlaceholder = styled.div`
  background-color: #1c1616;
  height: 60px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
  animation: pulse 1.5s ease-in-out infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.8;
    }
  }

  @media (max-width: 767px) {
    height: 50px;
    margin-bottom: 10px;
  }
`;

const NoTracksMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: #a0a0a0;
  font-style: italic;

  @media (max-width: 767px) {
    padding: 15px;
    font-size: 0.9em;
  }
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = styled(Loader)`
  animation: ${rotateAnimation} 2s linear infinite;
`;

const PlayOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.29, -0.81, 0.42, 1.68);
  border-radius: 4px;

  ${TrackImageWrapper}:hover & {
    opacity: 1!important;
  }
`;

const PlayButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1);
  transition: transform 0.5s cubic-bezier(0.29, -0.81, 0.42, 1.68);

  ${PlayOverlay}:hover & {
    transform: scale(1.2);
  }
`;

const shakeAnimation = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
`;

const DiceIcon = styled.img`
  width: 15px;
  height: 15px;
  animation: ${shakeAnimation} 0.5s ease-in-out;
  animation-iteration-count: 1;
  animation-play-state: paused;

  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
`;

const RerunButton = styled(ControlButton)`
  font-size: 12px;
  font-weight: bold;
  background-color: ${(props) => (props.disabled ? "#666" : "#f3b718")};
  color: #000;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover ${DiceIcon} {
    animation-play-state: running;
  }

  @media (max-width: 767px) {
    padding: 8px;
    margin-right: 0;
    display: flex;
    width: 42px;
    height: 33px;
    margin-right:10px;
  }
`;

const ShareModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(243, 183, 24, 0.1);
  z-index: 1000;
  max-width: 90%;
  width: 300px;
  color: #e0e0e0;
`;

const ShareModalTitle = styled.h3`
  color: #f3b718;
  margin-bottom: 15px;
  text-align: center;
`;

const ShareOptions = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const ShareOption = styled.button`
  background-color: ${(props) => props.color};
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #f3b718;
`;

const ShareLink = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #f3b718;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #1c1c1c;
  color: #e0e0e0;
`;

const CopyButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #f3b718;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f8d66d;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OverlaySpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #f3b718;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const AudioFileBadge = styled.div`
  position: absolute;
  bottom: -5px;
  right: -5px;
  background-color: rgba(243, 183, 24, 0.8);
  color: #000;
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GeneratedTracks = ({
  audioPlayerRef,
  tracks,
  setCurrentTrack,
  currentPlayingTrack,
  isPlaying,
  setIsPlaying,
  user,
  credits,
  setCredits,
  newTracks,
  setNewTracks,
  setIsAlert,
  setAlertContent,
  isLoggedIn,
  checkCDNAvailability,
  onStemExtraction,
  showAudioLoader,
  setShowAudioLoader,
  handleGenerateTrack,
  setFillInput,
  setAllTracksLoaded
}) => {
  const [loadingTracks, setLoadingTracks] = useState(true);
  const [cdnAvailability, setCdnAvailability] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const currentTrackRef = useRef(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [currentSharedTrack, setCurrentSharedTrack] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [isMobileRerun, setIsMobileRerun] = useState(false);
  const sharedLinkRef = useRef(null);
  const checkingTracks = useRef({});

  useEffect(() => {
    const checkTracksLoaded = () => {
      const trackElements = document.querySelectorAll('.TrackItem');
      if (trackElements.length === tracks.length) {
        setAllTracksLoaded(true);
      } else {
        setTimeout(() => {
          checkTracksLoaded();
        }, 500);
      }
    };

    if(tracks.length > 0) {
      checkTracksLoaded();
    }
  }, [tracks , setAllTracksLoaded]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingTracks(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const checkAvailability = async () => {
      const newAvailability = { ...cdnAvailability };
      let needsMoreChecks = false;

      for (const track of tracks) {
        const status = localStorage.getItem(`cdn_${track.id}`);
        if (status === "available") {
          newAvailability[track.id] = true;
        } else if (track.cdn_url && !checkingTracks.current[track.id]) {
          checkingTracks.current[track.id] = true;
          try {
            const response = await fetch(track.cdn_url);
            if (response.ok) {
              newAvailability[track.id] = true;
              localStorage.setItem(`cdn_${track.id}`, "available");
            } else if (response.status === 403) {
              needsMoreChecks = true; // Continue checking other tracks
            } else {
              needsMoreChecks = true;
            }
          } catch (error) {
            console.error(`Error checking CDN for track ${track.id}:`, error);
            needsMoreChecks = true;
          } finally {
            delete checkingTracks.current[track.id];
          }
        } else if (!status && track.cdn_url) {
          needsMoreChecks = true;
        }
      }

      setCdnAvailability(newAvailability);

      if (needsMoreChecks) {
        setTimeout(checkAvailability, 10000); // Check again in 10 seconds
      }
    };

    checkAvailability();
  }, [tracks]);

  useEffect(() => {
    if (currentPlayingTrack) {
      const scrollTimeout = setTimeout(() => {
        if (currentTrackRef.current) {
          currentTrackRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }, 100);

      return () => clearTimeout(scrollTimeout);
    }
  }, [currentPlayingTrack]);

  const getImage = async (event, id) => {
    setTimeout(() => {
      event.target.src = `https://cdn2.suno.ai/image_${id}.jpeg`;
    }, 4000);
  };


const debouncedHandlePlayPauseClick = useCallback(
  debounce((track) => {
    // Your logic for handling track change
    if (currentPlayingTrack && currentPlayingTrack.id !== track.id) {
      setCurrentTrack(track);
      if (isPlaying) {
        audioPlayerRef.current.audio.current.pause();
      } else {
        audioPlayerRef.current.audio.current.play().catch(error => {
          console.error('Play failed', error);
        });
      }
    } else {
      if(!currentPlayingTrack) {
        setCurrentTrack(track);
      }
      if (isPlaying) {
        audioPlayerRef.current.audio.current.pause();
      } else {
        audioPlayerRef.current.audio.current.play().catch(error => {
          console.error('Play failed', error);
        });
      }
    }
  }, 10),
  [currentPlayingTrack, isPlaying, audioPlayerRef]
);

// Use this debounced function in your event handlers
const handlePlayPauseClick = (track) => {
  debouncedHandlePlayPauseClick(track);
};
  const handleRegularDownload = async (track) => {
    if (!cdnAvailability[track.id]) {
      console.log("CDN not available for download");
      return;
    }

    try {
      const response = await fetch(track.cdn_url);
      if (!response.ok) throw new Error("Failed to fetch audio");
      const audioBlob = await response.blob();

      const downloadUrl = URL.createObjectURL(audioBlob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = `${track.title}.mp3`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);

      setIsAlert(true);
      setAlertContent({
        title: "Success",
        message: `${track.title} downloaded successfully!`,
      });
    } catch (error) {
      console.error("Download error:", error);
      setIsAlert(true);
      setAlertContent({
        title: "Error",
        message: "Failed to download the track. Please try again.",
      });
    }
  };

  const handleRerun = async (track) => {
    if (window.innerWidth <= 700) {
      handleRerunForMobile(track);
      return;
    }
    try {
      if (track.uploadedFile) {
        const file = await localforage.getItem(track.uploadedFile);
        setFillInput({
          title: track.title,
          tags: track.tags,
          selectedFile: file,
        });
      } else {
        setFillInput({ title: track.title, tags: track.tags });
      }
    } catch (error) {
      console.error("Rerun error:", error);
      setIsAlert(true);
      setAlertContent({
        title: "Error",
        message: "Failed to rerun the track. Please try again.",
      });
    }
  };

  const handleRerunForMobile = async (track) => {
    try {
      setIsMobileRerun(true);
      if (track.uploadedFile) {
        const file = await localforage.getItem(track.uploadedFile);
        await handleGenerateTrack(track.title, track.tags, file);
        setIsMobileRerun(false);
      } else {
        await handleGenerateTrack(track.title, track.tags);
        setIsMobileRerun(false);
      }
      document.querySelector(".GeneratedTracks3D").scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch (err) {
      setIsMobileRerun(false);
      setIsAlert(true);
      setAlertContent({
        title: "Error",
        message: "Failed to rerun the track. Please try again.",
      });
    }
  };

  const handleShareTrack = async (track) => {
    if (!cdnAvailability[track.id]) {
      console.log("CDN not available for sharing");
      return;
    }
    setCurrentSharedTrack(track);
    setShareModalOpen(true);
    const link = await generateShareableLink(
      track.id,
      track.cdn_url,
      track.title,
      track.tags,
      track.image_url
    );
    setShareLink(link);
  };

  const generateShareableLink = async (
    fileId,
    fileLink,
    title,
    tags,
    imageUrl
  ) => {
    const trackData = {
      title,
      imageUrl,
      tags,
      fileUrl: fileLink,
    };
    const encodedData = btoa(JSON.stringify(trackData));
    const longUrl = `https://samplegenerator.xyz/listen/${fileId}?data=${encodedData}`;

    try {
      const shortUrl = await shortenUrl(longUrl);
      return shortUrl;
    } catch (error) {
      console.error("Error shortening URL:", error);
      return longUrl; // Return the long URL if shortening fails
    }
  };

  const handleCopyLink = async () => {
    try {
      if (sharedLinkRef.current) {
        await navigator.clipboard.writeText(sharedLinkRef.current.value);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset copied state after 2 seconds
      }
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <Container className="GeneratedTracks3D">
      <Title>Generated Tracks</Title>
      <TrackList>
        {loadingTracks ? (
          Array(3)
            .fill()
            .map((_, index) => <LoadingPlaceholder key={index} />)
        ) : tracks.length === 0 ? (
          <NoTracksMessage>
            No tracks generated yet. Create some music!
          </NoTracksMessage>
        ) : (
          tracks.map((track) => (
            <TrackItem
              className="TrackItem"
              key={track.id}
              $isPlaying={
                currentPlayingTrack &&
                currentPlayingTrack.id === track.id &&
                isPlaying
              }
              ref={
                currentPlayingTrack && currentPlayingTrack.id === track.id
                  ? currentTrackRef
                  : null
              }
            >
              <TrackImageWrapper>
                <TrackImage
                  src={
                    track.image_url === ""
                      ? `https://cdn2.suno.ai/image_${tracks.id}.jpeg`
                      : track.image_url || "/Infinity@1x-1.0s-200px-200px.svg"
                  }
                  alt={track.title}
                  onError={(e) => {
                    e.target.src = "/Infinity@1x-1.0s-200px-200px.svg";
                    getImage(e, track.id);
                  }}
                />
                <PlayOverlay style={{opacity : `${currentPlayingTrack && currentPlayingTrack.id === track.id ? 1 : 0}`}}>
                  <PlayButton onClick={() => handlePlayPauseClick(track)}>
                    {currentPlayingTrack &&
                    currentPlayingTrack.id === track.id &&
                    isPlaying ? (
                      <Pause size={24} />
                    ) : showAudioLoader && showAudioLoader === track.id ? (
                      <LoadingSpinner size={24} />
                    ) : (
                      <Play size={24} />
                    )}
                  </PlayButton>
                </PlayOverlay>
                {newTracks && newTracks.includes(track.id) && <NewTrackDot />}
                {track.uploadedFile && (
                  <AudioFileBadge>
                    <AudioLines size={12} />
                  </AudioFileBadge>
                )}
              </TrackImageWrapper>
              <TrackInfo>
                <TrackTitle>{track.title || "Untitled"}</TrackTitle>
                <TrackGenre>
                  {track.tags || track.genre || "No Genre"}
                </TrackGenre>
              </TrackInfo>
              <TrackControls>
                <RerunButton
                  onClick={() => handleRerun(track)}
                  data-tooltip-id={`rerun-tooltip-${track.id}`}
                  data-tooltip-content="Rerun with the same prompts"
                >
                  <DiceIcon src="/icons8-dice-100.png" alt="Rerun" />
                </RerunButton>
                <ControlButton
                  onClick={() => handleRegularDownload(track)}
                  disabled={!cdnAvailability[track.id]}
                  data-tooltip-id={`download-tooltip-${track.id}`}
                  data-tooltip-content="Download the track"
                >
                  <Download size={20} />
                </ControlButton>
                <ShareButton
                  onClick={() => handleShareTrack(track)}
                  disabled={!cdnAvailability[track.id]}
                  data-tooltip-id={`share-tooltip-${track.id}`}
                  data-tooltip-content="Share with friends"
                >
                  <Share2 size={20} />
                </ShareButton>
                <ControlButton
                  className="StemExtractionButton"
                  onClick={() => onStemExtraction(track)}
                  disabled={!cdnAvailability[track.id] || !isLoggedIn}
                  data-tooltip-id={`stem-tooltip-${track.id}`}
                  data-tooltip-content="Stem extraction tool"
                >
                  <Layers size={20} />
                </ControlButton>
              </TrackControls>
              <Tooltip id={`rerun-tooltip-${track.id}`} />
              <Tooltip id={`download-tooltip-${track.id}`} />
              <Tooltip id={`share-tooltip-${track.id}`} />
              <Tooltip id={`stem-tooltip-${track.id}`} />
            </TrackItem>
          ))
        )}
      </TrackList>
      {shareModalOpen && (
        <ShareModal>
          <CloseButton
            onClick={() => {
              setShareModalOpen(false);
              setShareLink("");
            }}
          >
            <X size={20} />
          </CloseButton>
          <ShareModalTitle>Share "{currentSharedTrack.title}"</ShareModalTitle>
          <ShareOptions>{/* Add more share options here */}</ShareOptions>
          <ShareLink
            ref={sharedLinkRef}
            value={shareLink || "Loading..."}
            readOnly
          />
          <CopyButton onClick={handleCopyLink}>
            {isCopied ? "Copied" : "Copy Link"}
          </CopyButton>
        </ShareModal>
      )}
      {isMobileRerun && window.innerWidth <= 700 && (
        <LoadingOverlay>
          <OverlaySpinner />
          <p>Generating tracks...</p>
        </LoadingOverlay>
      )}
    </Container>
  );
};

export default GeneratedTracks;