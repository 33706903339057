import React, { useState, useEffect, useRef } from "react";
import styled, { css, keyframes } from "styled-components";
import {
  FiRefreshCw,
  FiUpload,
  FiMic,
  FiX,
  FiCheck,
  FiPlay,
  FiPause,
  FiHeadphones,
  FiClock,
  FiZap
} from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import { allTags, genreMap } from "../data/genres";

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const Container = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 20px;
  background: rgba(10, 10, 10, 0.9);
  border-radius: 15px 15px 0 0;
  color: #e0e0e0;
  font-family: "Roboto", "Arial", sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(243, 183, 24, 0.5) rgba(10, 10, 10, 0.1);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(10, 10, 10, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(243, 183, 24, 0.5);
    border-radius: 3px;
  }

  @media (min-width: 768px) {
    position: fixed;
    top: 80px;
    left: 20px;
    width: calc(50% - 40px);
    max-height: calc(100vh - 180px);
    border-radius: 15px;
  }

  @media (max-width: 767px) {
    margin: 10px auto 0;
    max-width: 95%;
    height: auto;
    max-height: calc(50vh - 60px);
    border-bottom: 2px solid #f3b718;
  }
`;

const Title = styled.h2`
  color: #f3b718;
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  background: rgba(30, 30, 30, 0.8);
  color: #e0e0e0;
  border: 1px solid #f3b718;
  border-radius: 8px;
  font-size: 14px;
  resize: none;
  height: ${(props) => props.rows * 1.5}em;
  line-height: 1.5;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(243, 183, 24, 0.5);
  }

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const CharCount = styled.div`
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 12px;
  color: ${(props) => (props.$isLimit ? "#ff6b6b" : "#a0a0a0")};
`;

const TagScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
  margin-bottom: 15px;
  white-space: nowrap;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: rgba(243, 183, 24, 0.5) rgba(10, 10, 10, 0.1);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(10, 10, 10, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(243, 183, 24, 0.5);
    border-radius: 3px;
  }

  &:hover::-webkit-scrollbar {
    opacity: 1;
  }

  @media (max-width: 767px) {
    padding-bottom: 8px;
  }
`;

const Tag = styled.div`
  background: #f3b718;
  color: #000;
  padding: 10px 15px;
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  transition: all 0.2s ease;

  &:hover {
    background: #f8d66d;
    transform: translateY(-2px);
  }

  @media (max-width: 767px) {
    padding: 8px 12px;
    font-size: 14px;
  }
`;

const IconButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const iconAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const IconButton = styled.button`
  background: ${(props) => (props.$isRecording ? "rgba(255, 0, 0, 0.8)" : "rgba(30, 30, 30, 0.8)")};
  color: ${(props) => (props.$isRecording ? "#ffffff" : "#f3b718")};
  border: none;
  border-radius: 8px;
  padding: 10px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.2s ease;
  flex: 1;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  position: relative;

  &:hover:not(:disabled) {
    background: ${(props) => (props.$isRecording ? "rgba(255, 0, 0, 0.9)" : "rgba(50, 50, 50, 0.8)")};
    transform: translateY(-2px);
    animation: ${iconAnimation} 0.5s ease infinite;
  }

  &:active:not(:disabled) {
    transform: translateY(1px);
  }

  ${(props) =>
    props.$isRecording &&
    css`
      animation: ${pulse} 1s infinite;
    `}

  @media (max-width: 767px) {
    padding: 8px;
  }
`;

const CreateButton = styled.button`
  width: 100%;
  padding: 12px;
  background: ${(props) =>
    props.$isEnabled ? "#f3b718" : "rgba(30,30,30,0.8)"};
  color: ${(props) => (props.$isEnabled ? "#000000" : "#a0a0a0")};
  border: none;
  border-radius: 8px;
  cursor: ${(props) => (props.$isEnabled ? "pointer" : "not-allowed")};
  font-size: 16px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover:not(:disabled) {
    background: ${(props) =>
      props.$isEnabled ? "#f8d66d" : "rgba(30,30,30,0.8)"};
    transform: ${(props) => (props.$isEnabled ? "translateY(-2px)" : "none")};
  }

  &:active:not(:disabled) {
    transform: translateY(1px);
  }

  @media (max-width: 767px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${(props) => props.$progress}%;
  background: rgba(243, 183, 24, 0.3);
  transition: width 0.3s ease-in-out;
`;

const AlertMessage = styled.div`
  margin-top: 15px;
  padding: 10px;
  background: rgba(255, 0, 0, 0.2);
  color: #ff0000;
  border-radius: 8px;
  font-size: 12px;
  text-align: center;

  @media (max-width: 767px) {
    margin-top: 10px;
    padding: 8px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingGif = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;

  @media (max-width: 767px) {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;

const SelectedFile = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: #a0a0a0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(30, 30, 30, 0.8);
  padding: 8px 10px;
  border-radius: 8px;

  @media (max-width: 767px) {
    margin-top: 8px;
    padding: 6px 8px;
  }
`;

const ClearFileButton = styled.button`
  background: none;
  border: none;
  color: #f3b718;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const UpgradeAlert = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background: rgba(243, 183, 24, 0.9);
  color: #000;
  padding: 15px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: ${fadeOut} 0.5s ease-in-out 5s forwards;
`;

const WaitTimeComparison = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: #a0a0a0;
  text-align: center;
`;

const MusicStyleSelector = ({
  onGenerateTrack,
  isOffline,
  uploadMusic,
  extendMusic,
  getGeneratedMusic,
  fetchGeneratedMusic,
  updateTracks,
  setIsAlert,
  setAlertContent,
  isLoading: externalIsLoading,
  setIsLoading: setExternalIsLoading,
  isLoggedIn,
  credits,
  fillInput,
  setFillInput,
  userPlan,
}) => {
  const [musicStyle, setMusicStyle] = useState("");
  const [title, setTitle] = useState("");
  const [error, setError] = useState(null);
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [removingTags, setRemovingTags] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const musicStyleInputRef = useRef(null);
  const titleInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioInput, setAudioInput] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [isQueued, setIsQueued] = useState(false);
  const [queueProgress, setQueueProgress] = useState(0);
  const [showUpgradeAlert, setShowUpgradeAlert] = useState(false);
  const [showWaitTimeComparison, setShowWaitTimeComparison] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const MUSIC_STYLE_LIMIT = 120;
  const TITLE_LIMIT = 28;
  const MIN_FILE_DURATION = 6; // Minimum file duration in seconds

  const numberOfTagsPerContainer = 4;

  const firstContainerTags = suggestedTags.slice(0, numberOfTagsPerContainer);
  const secondContainerTags = suggestedTags.slice(
    numberOfTagsPerContainer,
    2 * numberOfTagsPerContainer
  );

  useEffect(() => {
    getRandomTags();
  }, []);

  useEffect(() => {
    if (fillInput) {
      setMusicStyle(fillInput.tags || "");
      setTitle(fillInput.title || "");
      if (fillInput.selectedFile) {
        setSelectedFile(fillInput.selectedFile);
        setAudioInput("upload");
        setAudioBlob(fillInput.selectedFile);
      } else {
        setSelectedFile(null);
        setAudioInput(null);
        setAudioBlob(null);
      }
    }
  }, [fillInput]);

  const handleCreate = async () => {
    if (credits === 0) {
      setIsAlert(true);
      setAlertContent({
        title: "No Credits",
        message: isLoggedIn
          ? "You've used all your credits. Please purchase more to continue."
          : "You've used your free generation. Sign up to get 5 more free credits!",
      });
      return;
    }

    try {
      setError(null);

      if (userPlan === "Starter") {
        setIsQueued(true);
        setShowWaitTimeComparison(true);

        // Randomly decide if we're giving a taste of faster generation (20% chance)
        const giveInstantAccess = Math.random() < 0.2;

        if (giveInstantAccess) {
          setShowUpgradeAlert(true);
          setTimeout(() => setShowUpgradeAlert(false), 5000);
          await new Promise(resolve => setTimeout(resolve, 1000)); // Quick 1-second delay
        } else {
          const queueTime = Math.floor(Math.random() * (20000 - 15000 + 1)) + 15000; // Random time between 15-20 seconds
          const startTime = Date.now();

          const interval = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const progress = Math.min((elapsedTime / queueTime) * 100, 100);
            setQueueProgress(progress);
          }, 100);

          await new Promise(resolve => setTimeout(resolve, queueTime));
          clearInterval(interval);
        }

        setIsQueued(false);
        setQueueProgress(0);
        setShowWaitTimeComparison(false);
      }

      setIsLoading(true);
      startProgressSimulation();

      const result = await onGenerateTrack(
        musicStyle,
        title || "Untitled",
        selectedFile
      );

      if (result && result.length > 0) {
        setMusicStyle("");
        setTitle("");
        setSelectedFile(null);
        setAudioBlob(null);
        setAudioInput(null);
        getRandomTags();
      } else {
        throw new Error("No tracks were generated. Please try again.");
      }
    } catch (err) {
      console.error("[Client] Error in MusicStyleSelector:", err);
      setError(err.message);
    } finally {
      setIsLoading(false);
      stopProgressSimulation();
    }
  };

  const handleMusicStyleChange = (e) => {
    const input = e.target.value;
    if (input.length <= MUSIC_STYLE_LIMIT) {
      setMusicStyle(input);
    }
  };

  const handleTitleChange = (e) => {
    const input = e.target.value;
    if (input.length <= TITLE_LIMIT) {
      setTitle(input);
    }
  };

  const getRandomTags = (clickedTag = null) => {
    const currentTags = musicStyle
      .split(",")
      .map((tag) => tag.trim().toLowerCase());

    let baseTags = allTags.filter(
      (tag) => !currentTags.includes(tag.toLowerCase())
    );

    if (clickedTag) {
      baseTags = genreMap[clickedTag] || [];
    } else {
      const rootTags = Object.keys(genreMap);
      baseTags =
        genreMap[rootTags[Math.floor(Math.random() * rootTags.length)]] || [];
    }

    baseTags = [...new Set(baseTags)];
    const availableTags = baseTags.filter(
      (tag) => !currentTags.includes(tag.toLowerCase())
    );

    const tagCount = 10; // Number of tags to display

    if (availableTags.length < tagCount) {
      const unusedTags = allTags.filter(
        (tag) => !currentTags.includes(tag.toLowerCase())
      );
      const shuffled = unusedTags.sort(() => 0.5 - Math.random());
      setSuggestedTags(shuffled.slice(0, tagCount));
    } else {
      const shuffled = availableTags.sort(() => 0.5 - Math.random());
      setSuggestedTags(shuffled.slice(0, tagCount));
    }
  };

  const handleTagClick = (tag) => {
    setMusicStyle((prev) => {
      const currentTags = prev.split(",").map((t) => t.trim());
      if (
        !currentTags.includes(tag) &&
        prev.length + tag.length + 2 <= MUSIC_STYLE_LIMIT
      ) {
        return prev ? `${prev}, ${tag}` : tag;
      }
      return prev;
    });

    setRemovingTags([...removingTags, tag]);
    setTimeout(() => {
      getRandomTags(tag);
      setRemovingTags((prevRemoving) => prevRemoving.filter((t) => t !== tag));
    }, 200);
  };

  const startProgressSimulation = () => {
    setLoadingProgress(5);
    let progress = 5;
    const interval = setInterval(() => {
      progress += Math.random() * 15;
      if (progress >= 90) {
        clearInterval(interval);
        setLoadingProgress(90);
      } else {
        setLoadingProgress(progress);
      }
    }, 500);
  };

  const stopProgressSimulation = () => {
    setLoadingProgress(100);
    setTimeout(() => setLoadingProgress(0), 200);
  };

  const checkFileDuration = (file) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio();
      audio.onloadedmetadata = () => {
        URL.revokeObjectURL(audio.src);
        if (audio.duration < MIN_FILE_DURATION) {
          reject(
            `File duration is too short. Please upload a file that is at least ${MIN_FILE_DURATION} seconds long.`
          );
        } else {
          resolve();
        }
      };
      audio.onerror = () => {
        URL.revokeObjectURL(audio.src);
        reject("Error loading audio file.");
      };
      audio.src = URL.createObjectURL(file);
    });
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        await checkFileDuration(file);
        setSelectedFile(file);
        setAudioInput("upload");
        setAudioBlob(file);
        setError(null);
      } catch (error) {
        setSelectedFile(null);
        setAudioBlob(null);
        setError(error);
      }
    }
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
    setAudioInput(null);
    setAudioBlob(null);
    setError(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }
    setIsPlaying(false);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const toggleRecording = () => {
    setIsRecording((prevIsRecording) => {
      if (prevIsRecording) {
        stopRecording();
      } else {
        startRecording();
      }
      return !prevIsRecording;
    });
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        const chunks = [];
        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data);
          }
        };

        recorder.onstop = () => {
          const blob = new Blob(chunks, { type: "audio/webm" });
          setAudioBlob(blob);
          setSelectedFile(
            new File([blob], "recorded-audio.webm", { type: "audio/webm" })
          );
          setAudioInput("record");
        };

        recorder.start();
      })
      .catch((err) => {
        console.error("Error accessing the microphone:", err);
        setError(
          "Error accessing the microphone. Please check your permissions."
        );
        setIsRecording(false);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
    }
  };

  const togglePlayback = () => {
    if (audioBlob) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        if (!audioRef.current) {
          audioRef.current = new Audio(URL.createObjectURL(audioBlob));
          audioRef.current.onended = () => setIsPlaying(false);
        }
        audioRef.current.play();
        setIsPlaying(true);
      }
    }
  };

  const isCreateEnabled = musicStyle.trim().length > 0 && !isLoading && !isQueued;

  let buttonText = selectedFile ? "🔄 Remix" : "🚀 Create";
  if (isQueued) {
    buttonText = "Queued";
  } else if (isLoading) {
    buttonText = selectedFile ? "Remixing..." : "Creating...";
  }

  return (
    <Container>
      <Title>Create Your Music</Title>
      <InputWrapper>
        <StyledTextArea
          className="styledTextArea"
          ref={musicStyleInputRef}
          value={musicStyle}
          onChange={handleMusicStyleChange}
          placeholder="🎵 Describe your music style (e.g., jazz, pop, classical)"
          rows={3}
        />
        <CharCount $isLimit={musicStyle.length === MUSIC_STYLE_LIMIT}>
          {musicStyle.length}/{MUSIC_STYLE_LIMIT}
        </CharCount>
      </InputWrapper>

      <TagScrollContainer>
        {firstContainerTags.map((tag) => (
          <Tag
            key={tag}
            onClick={() => handleTagClick(tag)}
            $isRemoving={removingTags.includes(tag)}
          >
            {tag}
          </Tag>
        ))}
      </TagScrollContainer>
      <TagScrollContainer>
        {secondContainerTags.map((tag) => (
          <Tag
            key={tag}
            onClick={() => handleTagClick(tag)}
            $isRemoving={removingTags.includes(tag)}
          >
            {tag}
          </Tag>
        ))}
      </TagScrollContainer>
      <IconButtonContainer>
        <IconButton
         className="UploadButton"
          onClick={triggerFileInput}
          data-tooltip-id="upload-tooltip"
          data-tooltip-content="Upload an audio file"
        >
          <FiUpload size={20} />
          {audioInput === "upload" && (
            <FiCheck
              size={14}
              style={{
                position: "absolute",
                top: -4,
                right: -4,
                color: "#4caf50",
              }}
            />
          )}
        </IconButton>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          style={{ display: "none" }}
          accept="audio/*"
        />
        <IconButton
        className="RecordingButton"
          onClick={toggleRecording}
          $isRecording={isRecording}
          data-tooltip-id="record-tooltip"
          data-tooltip-content="Record audio"
        >
          <FiMic size={20} />
        </IconButton>
        {audioBlob && (
          <IconButton
            onClick={togglePlayback}
            data-tooltip-id="play-tooltip"
            data-tooltip-content={isPlaying ? "Pause" : "Play"}
          >
            {isPlaying ? <FiPause size={20} /> : <FiPlay size={20} />}
          </IconButton>
        )}
        <IconButton
          onClick={() => getRandomTags()}
          data-tooltip-id="refresh-tooltip"
          data-tooltip-content="Get new tag suggestions"
        >
          <FiRefreshCw size={20} />
        </IconButton>
      </IconButtonContainer>

      {selectedFile && (
        <SelectedFile>
          <span>{selectedFile.name.substring(0, 20)}...</span>
          <ClearFileButton onClick={clearSelectedFile}>
            <FiX size={16} />
          </ClearFileButton>
        </SelectedFile>
      )}

      <InputWrapper>
        <StyledTextArea
          ref={titleInputRef}
          value={title}
          onChange={handleTitleChange}
          placeholder="🏷️ Enter a title (optional)"
          rows={2}
        />
        <CharCount $isLimit={title.length === TITLE_LIMIT}>
          {title.length}/{TITLE_LIMIT}
        </CharCount>
      </InputWrapper>

      <CreateButton
        className="CreateButton"
        onClick={handleCreate}
        disabled={!isCreateEnabled}
        $isEnabled={isCreateEnabled}
      >
        <ProgressBar $progress={isQueued ? queueProgress : loadingProgress} />
        <span style={{ position: "relative", zIndex: 1 }}>
          {isQueued || isLoading ? (
            <LoadingContainer>
              <LoadingGif
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              />
              {buttonText}
            </LoadingContainer>
          ) : (
            buttonText
          )}
        </span>
      </CreateButton>

      {error && <AlertMessage>{error}</AlertMessage>}

      {showWaitTimeComparison && (
        <WaitTimeComparison>
          <FiClock /> Starter: 15-20s | <FiZap /> Pro: Instant
        </WaitTimeComparison>
      )}

      {showUpgradeAlert && (
        <UpgradeAlert>
          Upgrade to Pro for instant generation every time!
        </UpgradeAlert>
      )}

      <Tooltip id="upload-tooltip" />
      <Tooltip id="record-tooltip" />
      <Tooltip id="play-tooltip" />
      <Tooltip id="tab-recorder-tooltip" />
      <Tooltip id="refresh-tooltip" />
    </Container>
  );
};

export default MusicStyleSelector;