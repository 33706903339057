import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import {collection , getDocs} from "firebase/firestore"
import {db} from "../firebase"
import { signInWithEmailAndPassword } from "firebase/auth";
import styled from "styled-components";
import {
  AlertCircle,
  Clock,
  Database,
  DollarSign,
  Users,
  Music,
  Server,
  Headphones,
  BarChart,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [dashboardData, setDashboardData] = useState({
    credits: 0,
    apiCalls: 0,
    timeRemaining: 0,
    totalUsers: 0,
    newUsers: 0,
    activeUsers: 0,
    profits: 0,
    // totalAudioFiles: 0,
    // recentAudioRequests: 0,
    // serverUptime: 0,
    // averageResponseTime: 0,
    // monthlyRevenue: 0,
    // activeSubscriptions: 0,
    // openSupportTickets: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && user.email === "webdevakshat@gmail.com") {
        setIsAuthenticated(true);
        fetchAdminData();
      } else {
        setIsAuthenticated(false);
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const convertMinutesToMinutesSeconds = (minutes) => {
    if (isNaN(minutes) || typeof minutes !== "number") {
      throw new Error("Invalid input: Please provide a number.");
    }
    const totalSeconds = Math.round(minutes * 60);
    const mins = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${mins}m ${seconds < 10 ? "0" : ""}${seconds}s`;
  };


  const fetchAdminData = async () => {
    try {
      const response1 = await fetch(
        `https://audio-gen-server.vercel.app/api/get-limits?action=getLalaiLimits`
      );
      const response2 = await fetch(
        `https://audio-gen-server.vercel.app/api/get-limits?action=getSunoCredits`
      );
      const timeLimit = await response1.json();
      const creditLimit = await response2.json();
      const credits = JSON.parse(creditLimit);
      const minutesLeft = convertMinutesToMinutesSeconds(
        timeLimit.process_duration_left
      );

      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const userCount = usersSnapshot.size
      const usersData = usersSnapshot.docs.map((doc) => doc.data());
      const apiCalls = usersData.reduce((sum, user) => {
        if (typeof user["apiCalls"] === 'number') {
          return sum + user["apiCalls"];
        }
        return sum;
      }, 0)

      let ActiveUsers_7Days = 0;
      let NewUsers_30Days = 0;
      usersData.forEach((data) =>{
        if(typeof data["last_active"] === 'number'){
          const timeDifference = Date.now() - parseInt(data["last_active"], 10);
          const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
          if(daysDifference <= 7){
            ActiveUsers_7Days += 1
          }
        }

        if(typeof data["created_at"] === 'number'){
          const timeDifference = Date.now() - parseInt(data["created_at"], 10);
          const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
          if(daysDifference <= 30){
            NewUsers_30Days += 1
          }
        }

      })



      // Fetch other data (you would replace these with actual API calls)
      // const usersResponse = await fetch('https://audio-gen-server.vercel.app/api/user-stats');
      // const usersData = await usersResponse.json();

      // const audioResponse = await fetch('https://audio-gen-server.vercel.app/api/audio-stats');
      // const audioData = await audioResponse.json();

      // const systemResponse = await fetch('https://audio-gen-server.vercel.app/api/system-stats');
      // const systemData = await systemResponse.json();

      // const financialResponse = await fetch('https://audio-gen-server.vercel.app/api/financial-stats');
      // const financialData = await financialResponse.json();

      // const supportResponse = await fetch('https://audio-gen-server.vercel.app/api/support-stats');
      // const supportData = await supportResponse.json();

      setDashboardData({
        credits: credits.credits_left,
        apiCalls: apiCalls,
        timeRemaining: minutesLeft,
        // profits: financialData.totalProfits,
        totalUsers: userCount,
        newUsers: NewUsers_30Days,
        activeUsers: ActiveUsers_7Days,
        // totalAudioFiles: audioData.totalAudioFiles,
        // recentAudioRequests: audioData.recentRequests,
        // serverUptime: systemData.uptime,
        // averageResponseTime: systemData.avgResponseTime,
        // monthlyRevenue: financialData.monthlyRevenue,
        // activeSubscriptions: financialData.activeSubscriptions,
        // openSupportTickets: supportData.openTickets
      });
    } catch (error) {
      console.error("Error fetching admin data:", error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error("Login error:", error);
      setError("Invalid credentials. Please try again.");
    }
  };

  if (!isAuthenticated) {
    return (
      <Container>
        <Title>Admin Dashboard</Title>
        <LoginForm onSubmit={handleLogin}>
          <h2>Admin Login</h2>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit">Login</Button>
        </LoginForm>
      </Container>
    );
  }

  return (
    <Container>
      <Title>Admin Dashboard</Title>
      <StatsContainer>
        <StatCard>
          <StatIcon>
            <Database size={24} />
          </StatIcon>
          <StatValue>
            {dashboardData.credits || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
          </StatValue>
          <StatLabel>Available Credits</StatLabel>
        </StatCard>
        <StatCard>
          <StatIcon>
            <AlertCircle size={24} />
          </StatIcon>
          <StatValue>
            {dashboardData.apiCalls || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
          </StatValue>
          <StatLabel>API Calls Made</StatLabel>
        </StatCard>
        <StatCard>
          <StatIcon>
            <Clock size={24} />
          </StatIcon>
          <StatValue>
            {dashboardData.timeRemaining || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
          </StatValue>
          <StatLabel>Time Remaining</StatLabel>
        </StatCard>
        {/* <StatCard>
          <StatIcon>
            <DollarSign size={24} />
          </StatIcon>
          <StatValue>
            $
            {dashboardData.profits || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
          </StatValue>
          <StatLabel>Total Profits</StatLabel>
        </StatCard>
        <StatCard>
          <StatIcon>
            <Users size={24} />
          </StatIcon>
          <StatValue>
            {dashboardData.totalUsers || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
          </StatValue>
          <StatLabel>Total Users</StatLabel>
        </StatCard> */}
        <StatCard>
          <StatIcon>
            <Users size={24} />
          </StatIcon>
          <StatValue>
            {dashboardData.newUsers || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
          </StatValue>
          <StatLabel>New Users (30 days)</StatLabel>
        </StatCard>
        <StatCard>
          <StatIcon>
            <Users size={24} />
          </StatIcon>
          <StatValue>
            {dashboardData.activeUsers || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
          </StatValue>
          <StatLabel>Active Users (7 days)</StatLabel>
        </StatCard>
        {/* <StatCard>
          <StatIcon>
            <Music size={24} />
          </StatIcon>
          <StatValue>
            {dashboardData.totalAudioFiles || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
          </StatValue>
          <StatLabel>Total Audio Files</StatLabel>
        </StatCard>
        <StatCard>
          <StatIcon>
            <Music size={24} />
          </StatIcon>
          <StatValue>
            {dashboardData.recentAudioRequests || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
          </StatValue>
          <StatLabel>Audio Requests (24h)</StatLabel>
        </StatCard>
        <StatCard>
          <StatIcon>
            <Server size={24} />
          </StatIcon>
          <StatValue>
            {dashboardData.serverUptime || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
            %
          </StatValue>
          <StatLabel>Server Uptime</StatLabel>
        </StatCard>
        <StatCard>
          <StatIcon>
            <Clock size={24} />
          </StatIcon>
          <StatValue>
            {dashboardData.averageResponseTime || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
            ms
          </StatValue>
          <StatLabel>Avg Response Time</StatLabel>
        </StatCard>
        <StatCard>
          <StatIcon>
            <DollarSign size={24} />
          </StatIcon>
          <StatValue>
            $
            {dashboardData.monthlyRevenue || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
          </StatValue>
          <StatLabel>Monthly Revenue</StatLabel>
        </StatCard>
        <StatCard>
          <StatIcon>
            <Headphones size={24} />
          </StatIcon>
          <StatValue>
            {dashboardData.activeSubscriptions || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
          </StatValue>
          <StatLabel>Active Subscriptions</StatLabel>
        </StatCard>
        <StatCard>
          <StatIcon>
            <BarChart size={24} />
          </StatIcon>
          <StatValue>
            {dashboardData.openSupportTickets || (
              <img
                className="h-6"
                src="/Infinity@1x-1.0s-200px-200px.svg"
                alt="Loading"
              ></img>
            )}
          </StatValue>
          <StatLabel>Open Support Tickets</StatLabel>
        </StatCard> */}
      </StatsContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: #000000;
  min-height: 100vh;
  width: 100%;
  color: #ffffff;
  padding: 20px;
  font-family: "Roboto", "Arial", sans-serif;
  overflow-y: auto;
`;

const Title = styled.h1`
  color: #f3b718;
  text-align: center;
  margin-bottom: 40px;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const StatCard = styled.div`
  background-color: rgba(30, 30, 30, 0.8);
  border-radius: 10px;
  padding: 20px;
  width: calc(25% - 20px);
  min-width: 200px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    width: calc(33.33% - 20px);
  }

  @media (max-width: 768px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const StatIcon = styled.div`
  color: #f3b718;
  margin-bottom: 10px;
`;

const StatValue = styled.div`
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const StatLabel = styled.div`
  font-size: 0.9rem;
  color: #a0a0a0;
`;

const LoginForm = styled.form`
  background-color: rgba(30, 30, 30, 0.8);
  border-radius: 10px;
  padding: 40px;
  max-width: 400px;
  margin: 100px auto;
  text-align: center;

  @media (max-width: 600px) {
    padding: 20px;
    margin: 50px auto;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #1c1c1c;
  border: 1px solid #f3b718;
  border-radius: 5px;
  color: #ffffff;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #f3b718;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d9a116;
  }
`;

const ErrorMessage = styled.div`
  color: #ff6b6b;
  margin-bottom: 20px;
`;

export default AdminDashboard;
