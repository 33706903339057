import axios from 'axios';

const BITLY_API_ENDPOINT = 'https://api-ssl.bitly.com/v4/shorten';
const BITLY_ACCESS_TOKEN = process.env.REACT_APP_BITLY_ACCESS_TOKEN;

export const shortenUrl = async (longUrl) => {
  if (!BITLY_ACCESS_TOKEN) {
    console.error('Bitly access token is not set. Token value:', BITLY_ACCESS_TOKEN);
    throw new Error('Bitly access token is not set');
  }

  try {
    console.log('Sending request to Bitly API');
    const response = await axios.post(
      BITLY_API_ENDPOINT,
      { long_url: longUrl },
      {
        headers: {
          'Authorization': `Bearer ${BITLY_ACCESS_TOKEN}`,
          'Content-Type': 'application/json',
        },
      }
    );
    console.log('Bitly API response:', response.data);
    return response.data.link;
  } catch (error) {
    console.error('Error shortening URL:', error.response ? error.response.data : error.message);
    throw error;
  }
};