import React, { useState } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1a1a;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 10000;
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ModalContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 2rem;
  color: #ffffff;


  @media (max-width:530px){
  margin-top:3rem;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #333;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 #333;

  h1, h2, h3, h4, h5, h6 {
    color: #f3b718;
  }

  p, li {
    line-height: 1.6;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  background: none;
  border: none;
  color: #f3b718;
  font-size: 2rem;
  cursor: pointer;
  z-index: 10001;
`;

const SwitchButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 3.5rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  z-index: 10001;
  border:2px solid #f3b718;
  padding : 0.5rem;
  background-color: #f3b718;
  color : black;
  font-weight: bold;
`

const Terms = `
# Terms and Conditions for samplegenerator.xyz

**Last Updated: August 12, 2024**

## 1. Agreement to Terms

These Terms and Conditions (the "Terms") constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you"), and Sample Generator ("we," "us," or "our"), concerning your access to and use of the samplegenerator.xyz website as well as any other media form, media channel, mobile website, or mobile application related, linked, or otherwise connected thereto (collectively, the "Website"). By accessing the Website, you agree that you have read, understood, and agree to be bound by all of these Terms. If you do not agree with all of these Terms, you are expressly prohibited from using the Website and must discontinue use immediately.

We reserve the right, at our sole discretion, to make changes or modifications to these Terms at any time and for any reason. We will alert you about any changes by updating the "Last Updated" date of these Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms by your continued use of the Website after the date such revised Terms are posted.

## 2. Description of Service

Samplegenerator.xyz provides an interface to access and utilize the SunoAPI, an unofficial API for Suno.com's AI-powered audio and visual content generation services. Our Website acts as an intermediary and does not directly provide the content generation services. The Website is designed to facilitate access to AI-generated content, allowing users to interact with and obtain creative outputs based on inputs submitted through the SunoAPI.

## 3. User Eligibility

By using this Website, you represent and warrant that you are at least 18 years of age or the age of majority in your jurisdiction and have the legal capacity to enter into these Terms. If you are accessing or using the Website on behalf of a company or entity, you represent that you have the authority to bind that company or entity to these Terms. If you do not meet these eligibility requirements, you may not use the Website.

## 4. Account Registration

You may be required to register for an account to access certain features of the Website. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your password and for any activities or actions under your account. You agree to notify us immediately of any unauthorized use of your account. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.

## 5. User Responsibilities

### 5.1 Content Submission

By submitting any content or data to our Website for processing through the SunoAPI, you represent and warrant that:
- You have the right to submit such content.
- The content does not violate any third-party rights, including intellectual property rights.
- The content is not illegal, obscene, defamatory, threatening, or otherwise objectionable.

### 5.2 Use of Generated Content

You are solely responsible for your use of any content generated through our Website via the SunoAPI. You agree to use such content in compliance with applicable laws and third-party rights. You acknowledge and agree that any reliance on or use of any generated content is at your own risk.

## 6. Intellectual Property

### 6.1 Website Content

All content on samplegenerator.xyz, including but not limited to text, graphics, logos, software, and the compilation thereof, is the property of samplegenerator.xyz or its content suppliers and is protected by Canadian and international copyright laws. You are granted a limited license to access and use the Website for your personal or business use. You agree not to copy, modify, distribute, sell, or lease any part of the Website or its content without our express written permission.

### 6.2 User-Generated and AI-Generated Content

- You retain any ownership rights you may have to content you submit to our Website.
- For content generated through the SunoAPI, ownership and usage rights may be subject to Suno.com's terms and conditions. We do not guarantee any specific ownership or usage rights for AI-generated content.

## 7. Prohibited Activities

You agree not to engage in any of the following activities:
- Violating laws or regulations
- Infringing on intellectual property rights
- Transmitting malicious code or interfering with the Website's functionality
- Attempting to gain unauthorized access to any part of the Website
- Using the Website for any illegal or unauthorized purpose

Additionally, you agree not to:
- Use the Website to defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights of others
- Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity
- Use the Website to send unauthorized or unsolicited advertising, spam, or other forms of solicitation

## 8. Disclaimer of Warranties

THE WEBSITE AND ITS SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. WE DO NOT GUARANTEE THE ACCURACY, COMPLETENESS, OR USEFULNESS OF ANY CONTENT GENERATED THROUGH OUR WEBSITE. WE DO NOT WARRANT THAT THE WEBSITE WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS OR VIRUSES.

## 9. Limitation of Liability

TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, SAMPLEGENERATOR.XYZ AND ITS AFFILIATES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES RESULTING FROM:
- YOUR USE OR INABILITY TO USE THE WEBSITE
- ANY CONTENT OBTAINED THROUGH THE WEBSITE
- UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS

IN NO EVENT SHALL SAMPLEGENERATOR.XYZ'S AGGREGATE LIABILITY FOR ALL CLAIMS RELATED TO THE WEBSITE EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE WEBSITE IN THE 12 MONTHS PRECEDING THE CLAIM.

## 10. Indemnification

You agree to indemnify and hold samplegenerator.xyz and its affiliates, officers, agents, employees, and partners harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the Website, your violation of these Terms, or your violation of any rights of another.

## 11. Third-Party Services

Our Website uses SunoAPI, an unofficial API for Suno.com. We are not affiliated with, endorsed by, or in any way officially connected with Suno.com or any of its subsidiaries or affiliates. The use of SunoAPI may be subject to separate terms and conditions. We do not control and are not responsible for third-party services or websites linked to or from our Website.

## 12. Modifications to Service

We reserve the right to modify or discontinue, temporarily or permanently, the Website or any features or portions thereof without prior notice. You agree that we will not be liable to you or any third party for any modification, suspension, or discontinuation of the Website or any part thereof.

## 13. Governing Law

These Terms shall be governed by and construed in accordance with the laws of the Province of Alberta and the federal laws of Canada applicable therein, without regard to its conflict of law provisions. You agree that any legal action or proceeding between samplegenerator.xyz and you for any purpose concerning these Terms or the parties' obligations hereunder shall be brought exclusively in a court of competent jurisdiction sitting in Calgary, Alberta, Canada.

## 14. Dispute Resolution

Any dispute arising from these Terms shall be resolved in the courts of Calgary, Alberta, Canada. You agree to the personal jurisdiction by and venue in such courts and waive any objection to such jurisdiction or venue.

## 15. Changes to Terms

We reserve the right to modify these Terms at any time. We will always post the most current version on our Website. By continuing to use the Website after changes have been made, you agree to be bound by the revised Terms. It is your responsibility to review these Terms periodically to stay informed of updates.

## 16. Severability

If any provision of these Terms is found to be unenforceable or invalid by a court of competent jurisdiction, that provision will be limited or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect.

## 17. Waiver

No waiver by samplegenerator.xyz of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of samplegenerator.xyz to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.

## 18. Contact Information

If you have any questions about these Terms, please contact us at [Your Contact Information].


`;


const Privacy = `

# Privacy Policy for SampleGenerator.xyz

Last Updated: August 12, 2024

## 1. Introduction

Welcome to SampleGenerator.xyz ("we", "our", or "us"). We are committed to protecting your privacy and handling your data in an open and transparent manner. This privacy policy explains how we collect, use, share, and protect your personal information when you use our website and services.

## 2. Information We Collect

We may collect the following types of information:

a) **Personal Information:**
   - Email address
   - Username
   - Password (encrypted)

b) **Usage Information:**
   - Log data (IP address, browser type, pages visited)
   - Device information

c) **Content:**
   - Any audio content you generate or upload through our service

## 3. How We Use Your Information

We use your information for the following purposes:

a) To provide and maintain our service  
b) To communicate with you about our service  
c) To improve and personalize our service  
d) To process payments (if applicable)  
e) To comply with legal obligations  

## 4. Cookies and Tracking Technologies

We use cookies and similar tracking technologies to collect and store certain information. You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies.

## 5. How We Share Your Information

We may share your information in the following circumstances:

a) With service providers who help us operate our website  
b) If required by law or to respond to legal process  
c) To protect our rights, property, or safety, or that of our users  

We do not sell your personal information.

## 6. Data Security

We implement appropriate technical and organizational measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.

## 7. Your Rights

Depending on your location, you may have certain rights regarding your personal information, including:

a) The right to access your personal information  
b) The right to correct or update your personal information  
c) The right to request deletion of your personal information  
d) The right to object to or restrict certain processing of your personal information  

To exercise these rights, please contact us using the information provided at the end of this policy.

## 8. Children's Privacy

Our service is not intended for use by children under the age of 18. We do not knowingly collect personal information from children under 18.

## 9. Changes to This Privacy Policy

We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the "Last Updated" date.

## 10. Third-Party Services

Our website uses SunoAPI, an unofficial API for Suno.com. We are not affiliated with, endorsed by, or officially connected with Suno.com or any of its subsidiaries or affiliates. The use of SunoAPI may be subject to separate terms and conditions.

## 11. Contact Us

If you have any questions about this privacy policy or our practices, please contact us at:

- Email: [Your Contact Email]
- SampleGenerator.xyz
- Address: Calgary, Alberta, Canada

## 12. Jurisdiction-Specific Provisions

### For Users in Canada:

We process your personal data based on the following legal bases:
- Consent
- Performance of a contract
- Legitimate interests
- Compliance with legal obligations

You have the right to lodge a complaint with your local data protection authority.

### For Users in Other Jurisdictions:

Additional rights or requirements may apply based on local laws. Please contact us for more information.

`



 const TCModal = ({onClose}) => {

  const [currentMarkdown, setCurrentMarkdown] = useState("Terms")


  return (
    <>
     <Overlay onClick={onClose} />
      <ModalContainer>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <SwitchButton onClick={(() => {
          setCurrentMarkdown(currentMarkdown === "Terms" ? "Privacy" : "Terms")
        })}>{currentMarkdown === "Terms" ? "Switch To Privacy & Policy" : "Switch To Terms & Conditions"}</SwitchButton>
        <ModalContent>
          <ReactMarkdown>{currentMarkdown==="Terms" ? Terms : Privacy}</ReactMarkdown>
        </ModalContent>
      </ModalContainer>
    </>
  )
}


export default TCModal;