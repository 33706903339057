import React, { useState, useEffect, useMemo } from 'react';
import { Text } from '@react-three/drei';
import * as THREE from 'three';

function BadgeText({ username }) {
  const [fontSize, setFontSize] = useState(0.2);
  const [lines, setLines] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const grungeTex = useMemo(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 256;
    canvas.height = 256;
    const ctx = canvas.getContext('2d');

    // Create a grainy background
    for (let x = 0; x < canvas.width; x++) {
      for (let y = 0; y < canvas.height; y++) {
        const value = Math.random() * 0.15 + 0.05;
        ctx.fillStyle = `rgba(255, 255, 255, ${value})`;
        ctx.fillRect(x, y, 1, 1);
      }
    }

    // Add some scratches
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.1)';
    for (let i = 0; i < 20; i++) {
      ctx.beginPath();
      ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.stroke();
    }

    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;
    return texture;
  }, []);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const words = username.split(' ');
    const verticalLines = [];

    words.forEach(word => {
      // Break the word into chunks of 8 characters and add each chunk as a separate line
      for (let i = 0; i < word.length; i += 8) {
        verticalLines.push(word.slice(i, i + 8));
      }
    });

    setLines(verticalLines);
    setFontSize(0.15);
  }, [username]);

  if (isMobile) {
    return null;
  }

  return (
    <group>
      {lines.map((line, index) => (
        <Text
          key={index}
          position={[0, 0.8 - index * fontSize, 0.01]}
          fontSize={fontSize}
          color="#f3b718"
          anchorX="center"
          anchorY="middle"
          maxWidth={0.9}
          font="/fonts/FlightSquareItalic-LVOBD.ttf"
          transparent={true}
          opacity={0.9}
        >
          {line}
        </Text>
      ))}
    </group>
  );
}

export default BadgeText;
