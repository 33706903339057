import { useEffect, useState } from "react";

const Alert = ({ isAlert, setIsAlert, alertContent }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isAlert) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
        setTimeout(() => setIsAlert(false), 300); // Allow time for fade-out animation
      }, 5000); // Increased to 5 seconds
      return () => clearTimeout(timer);
    }
  }, [isAlert, setIsAlert]);

  return (
    <div
      className={`${
        isVisible ? "block" : "hidden"
      } transition-opacity duration-300 ease-in-out z-50 fixed top-20 left-1/2 transform -translate-x-1/2 w-full max-w-md px-4`}
    >
      <div
        className="flex items-center p-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 shadow-lg"
        role="alert"
      >
        <svg
          className="flex-shrink-0 inline w-5 h-5 mr-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <div className="ml-3">
          <p className="font-medium">{alertContent.title || "Alert"}</p>
          <p className="mt-1">{alertContent.message || ""}</p>
        </div>
      </div>
    </div>
  );
};

export default Alert;