import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Canvas, extend } from "@react-three/fiber";
import { Environment, Lightformer } from "@react-three/drei";
import { Physics } from "@react-three/rapier";
import { MeshLineGeometry, MeshLineMaterial } from "meshline";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc, setDoc, deleteDoc, increment } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { auth, db, storage } from "./firebase";
import Band from "./components/Band";
import MusicStyleSelector3D from "./components/MusicStyleSelector3D";
import AudioPlayer3D from "./components/AudioPlayer3D";
import GeneratedTracks3D from "./components/GeneratedTracks3D";
import Navbar from "./components/Navbar";
import SubscriptionPage from "./components/SubscriptionPage";
import LandingPage from "./components/LandingPage";
import Alert from "./components/Alert";
import Tutorial from "./components/Tutorial";
import LoadingOverlay from "./components/LoadingOverlay";
import ConfettiComponent from "./components/Confetti";
import StemExtractionModal from "./components/StemExtractionModal";
import TCModal from "./components/TermsModal";
import SharedTrackPage from "./components/SharedTrackPage";
import AdminDashboard from "./components/AdminDashboard";
import SuccessPage from "./components/SuccessPage";
import localforage from "localforage";
import ReferPage from "./components/ReferPage"

extend({ MeshLineGeometry, MeshLineMaterial });

const AppContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: ${(props) => `url(${props.background})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 60px;
  padding-bottom: 80px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 80px 20px 100px;
  }
`;

const checkDuration = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const context = new (window.AudioContext || window.webkitAudioContext)();
      context.decodeAudioData(
        e.target.result,
        function (buffer) {
          const duration = buffer.duration;
          resolve(duration);
        },
        function (e) {
          reject(e);
        }
      );
    };

    reader.onerror = function (e) {
      reject(e);
    };

    reader.readAsArrayBuffer(file);
  });
};

const getExtensionFromMimeType = (mimeType) => {
  const mimeMap = {
    "audio/mpeg": "mp3",
    "audio/mp4": "mp4",
    "audio/wav": "wav",
    "audio/x-wav": "wav",
    "audio/wave": "wav",
    "audio/ogg": "ogg",
    "audio/x-aiff": "aiff",
    "audio/flac": "flac",
    "audio/aac": "aac",
    "audio/x-m4a": "m4a",
  };

  return mimeMap[mimeType] || "";
};

function determineFileExtension(file) {
  const fileNameExtension = file.name.split(".").pop().toLowerCase();
  const mimeTypeExtension = getExtensionFromMimeType(file.type);

  if (fileNameExtension === mimeTypeExtension || fileNameExtension !== "") {
    return fileNameExtension;
  }
  return mimeTypeExtension;
}

async function pollUploadStatus(uploadId, maxAttempts = 10, interval = 6000) {
  let attempts = 0;
  let parsedData;
  while (attempts < maxAttempts) {
    try {
      sessionStorage.setItem(
        "apiCalls",
        Number(sessionStorage.getItem("apiCalls")) + 1
      );
      const response = await fetch(
        `https://audio-gen-server.vercel.app/api/get-upload?action=Get`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ audioId: uploadId }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      parsedData = JSON.parse(data);


      if (parsedData.status === "complete") {
        return parsedData;
      }

      if (parsedData.status === "error") {
        return parsedData;
      }

      await new Promise((resolve) => setTimeout(resolve, interval));
      attempts++;
    } catch (error) {
      console.error("Error polling upload status:", error);
      return parsedData;
    }
  }

  throw new Error("Max polling attempts reached. Upload status not complete.");
}

const uploadMusic = async (file) => {
  let duration;
  let fileExtension;
  try {
    duration = await checkDuration(file);
    if (duration < 6 || duration > 60) {
      return {
        title: "Invalid Duration",
        message: "The audio file must be between 6 and 60 seconds in length.",
      };
    }
  } catch (error) {
    console.error("Error checking audio duration:", error);
    return {
      title: "Error",
      message: "Unable to check audio duration. Please try again.",
    };
  }

  try {
    fileExtension = await determineFileExtension(file);
    sessionStorage.setItem(
      "apiCalls",
      Number(sessionStorage.getItem("apiCalls")) + 1
    );
    const response1 = await fetch(
      "https://audio-gen-server.vercel.app/api/upload-music",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ extension: fileExtension }),
      }
    );
    const data = await response1.json();
    const uploadResponse1 = JSON.parse(data);
    const contentType = uploadResponse1.fields["Content-Type"];
    const { AWSAccessKeyId, key, policy, signature } = uploadResponse1.fields;
    const fileName = file.name;
    const audioId = uploadResponse1.id;

    const formData = new FormData();
    formData.append("Content-Type", contentType);
    formData.append("AWSAccessKeyId", AWSAccessKeyId);
    formData.append("policy", policy);
    formData.append("signature", signature);
    formData.append("key", key);
    formData.append("file", file);
    sessionStorage.setItem(
      "apiCalls",
      Number(sessionStorage.getItem("apiCalls")) + 1
    );
    const response2 = await fetch("https://suno-uploads.s3.amazonaws.com/", {
      method: "POST",
      mode: "no-cors",
      body: formData,
    });

    if (response2) {
      sessionStorage.setItem(
        "apiCalls",
        Number(sessionStorage.getItem("apiCalls")) + 1
      );
      const response3 = await fetch(
        "https://audio-gen-server.vercel.app/api/upload-music",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            audioName: fileName,
            audioId: audioId,
          }),
        }
      );

      const data3 = await response3.json();
      const uploadFinish = JSON.parse(data3);
      if (Object.keys(uploadFinish).length === 0) {

        const uploadStatus = await pollUploadStatus(audioId);

        if (
          uploadStatus.status === "error" ||
          uploadStatus.status.error_message
        ) {
          return { title: "Error", message: uploadStatus.error_message };
        }

        if (uploadStatus.status === "complete") {
          sessionStorage.setItem(
            "apiCalls",
            Number(sessionStorage.getItem("apiCalls")) + 1
          );
          const response4 = await fetch(
            "https://audio-gen-server.vercel.app/api/get-upload?action=Init",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                audioId: audioId,
              }),
            }
          );

          const data4 = await response4.json();
          const parsedData4 = JSON.parse(data4);
          if (parsedData4.clip_id) {
            const clip_id = parsedData4.clip_id;
            return {
              clip_id,
              duration: Number(duration.toFixed(3)),
            };
          } else {
            throw new Error("Failed to upload");
          }
        }
      } else if (uploadFinish.success === false) {
        console.error(
          "Upload failed: ",
          uploadFinish.message || "Unknown error"
        );
        throw new Error("Failed to upload");
      } else {
        console.error("Unexpected response format: ", uploadFinish);
        throw new Error("Failed to upload");
      }
    } else {
      throw new Error("Failed To Upload To S3 Bucket");
    }
  } catch (err) {
    console.log("Error uploading audio file", err);
    return {
      title: "Error",
      message: "Unable to upload. Please try again.",
    };
  }
};

const uploadMusicWithApi = async (
  file,
  maxRetries = 3,
  initialDelay = 1000
) => {
  let storageRef;
  let downloadURL;
  let duration;

  try {
    duration = await checkDuration(file);
    if (duration < 6 || duration > 60) {
      return {
        title: "Invalid Duration",
        message: "The audio file must be between 6 and 60 seconds in length.",
      };
    }
  } catch (error) {
    console.error("Error checking audio duration:", error);
    return {
      title: "Error",
      message: "Unable to check audio duration. Please try again.",
    };
  }

  try {
    storageRef = ref(storage, `audio/${Date.now()}_${file.name}`);
    await uploadBytes(storageRef, file);
    downloadURL = await getDownloadURL(storageRef);
  } catch (error) {
    console.error("Error uploading file to Firebase:", error);
    throw error;
  }

  const deleteFirebaseFile = async () => {
    try {
      await deleteObject(storageRef);
    } catch (deleteError) {
      console.error("Error deleting temporary file:", deleteError);
    }
  };

  const retrySunoApiCall = async (url, attempt = 1) => {
    try {
      const sunoResponse = await fetch(
        "https://audio-gen-server.vercel.app/api/upload-music-api",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ url: url }),
        }
      );

      if (sunoResponse.status === 500) {
        await deleteFirebaseFile();
        return {
          title: "Error",
          message: "Copyright Music Used. Try Again with a different file.",
        };
      }

      const data = await sunoResponse.json();
      await deleteFirebaseFile();
      if (typeof data.duration !== "number") {
        return { ...data, duration: duration };
      }

      return data;
    } catch (error) {
      if (attempt < maxRetries) {
        console.error(
          `Error with Suno API (attempt ${attempt}/${maxRetries}):`,
          error
        );
        const delay = initialDelay * Math.pow(2, attempt - 1);
        await new Promise((resolve) => setTimeout(resolve, delay));
        return retrySunoApiCall(url, attempt + 1);
      }
      await deleteFirebaseFile();
      throw error;
    }
  };

  try {
    const result = await retrySunoApiCall(downloadURL);
    return result;
  } finally {
    try {
      await getDownloadURL(storageRef);
      await deleteFirebaseFile();
    } catch (error) {
      console.log("File deleted already");
    }
  }
};

const extendMusicWithApi = async (tags, title, clipId, continue_at) => {
  try {
    const extendResult = await fetch(
      "https://audio-gen-server.vercel.app/api/extend-music-api",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tags,
          title,
          continue_clip_id: clipId,
          continue_at,
        }),
      }
    );

    const data = await extendResult.json();
    return data;
  } catch (error) {
    console.error("Error extending music:", error);
    throw error;
  }
};

const getTempUrl = (clipId) => `https://audiopipe.suno.ai/?item_id=${clipId}`;
const getCDNUrl = (clipId) => `https://cdn1.suno.ai/${clipId}.mp3`;

const cdnAvailabilityCache = new Map();

const checkCDNAvailability = async (url, retries = 5) => {
  if (cdnAvailabilityCache.has(url)) {
    return cdnAvailabilityCache.get(url);
  }

  try {
    const response = await fetch(url, { method: "HEAD" });

    if (response.ok) {
      cdnAvailabilityCache.set(url, true);
      return true;
    } else if (response.status === 403) {
      console.warn(`[Client] CDN availability check for ${url}: 403 Forbidden`);
      cdnAvailabilityCache.set(url, false);
      return false;
    } else if (response.status === 404) {
      console.warn(`[Client] CDN availability check for ${url}: 404 Not Found`);
      cdnAvailabilityCache.set(url, false);
      return false;
    } else {
      throw new Error(`Unexpected status: ${response.status}`);
    }
  } catch (error) {
    if (retries > 0) {
      await new Promise((resolve) => setTimeout(resolve, 7000));
      return checkCDNAvailability(url, retries - 1);
    }
    console.error(`Failed to check CDN availability for ${url}:`, error);
    cdnAvailabilityCache.set(url, false);
    return false;
  }
};

const getGeneratedMusic = async (clipId) => {
  try {
    const response = await fetch(
      "https://audio-gen-server.vercel.app/api/clip-music",
      {
        method: "POST",
        body: JSON.stringify({ clipId }),
        headers: { "Content-Type": "application/json" },
      }
    );

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    const data = await response.json();

    const updatedClips = data.map((clip) => ({
      ...clip,
      temp_url: getTempUrl(clip.id),
      cdn_url: getCDNUrl(clip.id),
    }));

    return updatedClips;
  } catch (error) {
    console.error("Error in getGeneratedMusic:", error);
    throw error;
  }
};

const generateTrack = async (tags) => {
  let result
  try {
    const requestBody = {
      prompt: "",
      tags,
      title: "",
      make_instrumental: true,
      wait_audio: true,
    };

    sessionStorage.setItem(
      "apiCalls",
      Number(sessionStorage.getItem("apiCalls")) + 1
    );
    const response = await fetch(
      "https://audio-gen-server.vercel.app/api/create-pure-music",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
     result = JSON.parse(data);

    if (result && result.length > 0) {
      return result.map((track) => ({
        ...track,
        temp_url: getTempUrl(track.id),
        cdn_url: getCDNUrl(track.id),
      }));
    } else {
      throw new Error("Invalid response from the API");
    }
  } catch (error) {
    console.error("Error generating track:", error);
    return result
  }
};

const fetchGeneratedMusic = async (clipIds, maxRetries = 50, delay = 5000) => {
  for (let i = 0; i < maxRetries; i++) {
    try {
      const tracks = await getGeneratedMusic(clipIds);
      const completedTracks = tracks.filter(
        (track) => track.status === "streaming"
      );

      if (completedTracks.length === clipIds.length) {
        return completedTracks;
      }

      if (i < maxRetries - 1) {
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    } catch (error) {
      console.error(
        `Error fetching generated music (attempt ${i + 1}/${maxRetries}):`,
        error
      );
      if (i === maxRetries - 1) throw error;
    }
  }

  throw new Error(
    "Failed to fetch all completed tracks after multiple attempts"
  );
};

const handleStemUpload = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch('https://www.lalal.ai/api/upload/' , {
        method : "POST",
        headers : {
        'Content-Disposition': `attachment; filename=${file.name}`,
        'Authorization': `license ${process.env.REACT_APP_STEM_API_KEY}`
        },
        body : formData
    })


  const result = await response.json();
  return result;
};

const handleStemSplit = async (stemId, params) => {
  const formData = new URLSearchParams();
  const fullParams = [...params];
  fullParams[0]["id"] = stemId;
  formData.append("params", JSON.stringify(fullParams));

  const response = await fetch(
    "https://audio-gen-server.vercel.app/api/stem-generate?action=split",
    {
      method: "POST",
      body: formData,
    }
  );

  const result = await response.json();
  return result;
};

const handleStemCheck = async (id) => {
  const formData = new URLSearchParams();
  formData.append("id", id);

  const response = await fetch(
    "https://audio-gen-server.vercel.app/api/stem-generate?action=check",
    {
      method: "POST",
      body: formData,
    }
  );

  const result = await response.json();
  return result;
};

const App = () => {
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [background, setBackground] = useState("/backgrounds/Sunset.png");
  const [generatedTracks, setGeneratedTracks] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [user, setUser] = useState(null);
  const [userPlan, setUserPlan] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [credits, setCredits] = useState(1);
  const [newTracks, setNewTracks] = useState([]);
  const [isOffline, setIsOffline] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(0);
  const [hasCompletedTutorial, setHasCompletedTutorial] = useState(false);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isStemModalOpen, setIsStemModalOpen] = useState(false);
  const [currentStemTrack, setCurrentStemTrack] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [showTCModal, setShowTCModal] = useState(false);
  const [showAudioLoader, setShowAudioLoader] = useState(false);
  const [fillInput, setFillInput] = useState({});
  const [allTracksLoaded, setAllTracksLoaded] = useState(false);
  const audioPlayerRef = useRef();

  const media = window.matchMedia("(min-width:750px)");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setAuthLoading(true);
      if (user) {
        setUser(user);
        setIsLoggedIn(true);
        setUsername(user.displayName || user.email);
        await fetchUserData(user.uid);
      } else {
        setUser(null);
        setIsLoggedIn(false);
        setUsername("");
        setCredits(0);
        setGeneratedTracks([]);
        setHasCompletedTutorial(false);
        setShowTutorial(false);
      }
      setAuthLoading(false);
    });

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      unsubscribe();
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    const bgValue = localStorage.getItem("background");
    if (bgValue) {
      setBackground(bgValue);
    }
  }, []);

  const handleOnline = () => setIsOffline(false);
  const handleOffline = () => setIsOffline(true);

  const fetchUserData = async (userId) => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        console.log(userDoc.data());
        const userData = userDoc.data();
        if(userDoc.data().alert){
          setIsAlert(true)
          setAlertContent({title: userDoc.data().alert.title, message: userDoc.data().alert.message})
          await updateDoc(userDocRef, {alert : null});
        }
        setUserPlan(userData.plan || "New Starter");
        setCustomerId(userData.customerId || null);
        setCredits(userData.credits || 5);
        setHasCompletedTutorial(userData.hasCompletedTutorial || false);
        const entries = Object.entries(userData.generatedTracks || {});
        entries.sort((a, b) => b[0] - a[0]);
        const tracksArray = entries.flatMap(([_, tracks]) => tracks);
        setGeneratedTracks(tracksArray);
        localStorage.removeItem("referralId");
      } else {
        // If the user document doesn't exist, create it with 5 credits
        const newUserData = {
          email: auth.currentUser?.email || '',
          credits: 5,
          hasCompletedTutorial: false,
          generatedTracks: [],
          plan: "New Starter",
          createdAt: new Date(),
          referralId : localStorage.getItem("referralId")
        };
        await setDoc(userDocRef, newUserData);
        setCredits(5);
        setUserPlan("New Starter");
        setHasCompletedTutorial(false);
        setShowTutorial(true);
        localStorage.removeItem("referralId");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      if (error.code === "failed-precondition") {
        setIsOffline(true);
      }
    }
  };

  const handleBackgroundChange = (newBackground) => {
    setBackground(newBackground);
  };

  const updateTracks = async (newTracks, usedCredits) => {
    if (!newTracks || newTracks.length === 0) {
      console.error("No new tracks to update");
      return;
    }

    const tracksToStore = newTracks.map((track) => ({
      id: track.id,
      title: track.title || "Untitled",
      tags: track.tags || [],
      cdn_url: track.cdn_url || "",
      temp_url: track.temp_url || "",
      image_url: track.image_url || "",
      lyrics: "",
      uploadedFile: track.uploadedFile || null,
    }));

    if (isLoggedIn && user) {
      try {
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, {
          credits: credits - usedCredits,
          [`generatedTracks.${Date.now()}`]: tracksToStore,
          apiCalls: increment(Number(sessionStorage.getItem("apiCalls")) || 0),
          last_active: Date.now(),
        });
      } catch (error) {
        console.error("Error updating tracks in Firestore:", error);
      }
    }

    setCredits((prevCredits) => prevCredits - usedCredits);
    setGeneratedTracks((prevTracks) => [...tracksToStore, ...prevTracks]);
    setNewTracks(tracksToStore.map((item) => item.id));
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleGenerateTrack = async (style, title, selectedFile) => {
    const requiredCredits = selectedFile ? 2 : 1;
    if (credits < requiredCredits) {
      setIsAlert(true);
      setAlertContent({
        title: "Insufficient Credits",
        message: `You need ${requiredCredits} credit${
          requiredCredits > 1 ? "s" : ""
        } for this action.`,
      });
      return;
    }

    try {
      setIsLoading(true);
      let newTracks;

      if (userPlan === "Starter") {
        setIsAlert(true);
        setAlertContent({
          title: "Generating Track",
          message: "Your track is queued. Upgrade for instant access!",
        });
        await delay(10000);
      }

      if (selectedFile) {
        const uploadResult = await uploadMusic(selectedFile);
        if (uploadResult.title && uploadResult.message) {
          setIsAlert(true);
          setAlertContent({
            title: uploadResult.message.includes("existing work")
              ? "Copyright Music"
              : uploadResult.title,
            message: uploadResult.message,
          });
          return;
        }

        if (uploadResult.duration < 6) {
          setIsAlert(true);
          setAlertContent({
            title: "Error",
            message: "Error Generating Audio. Please try again.",
          });
        }

        sessionStorage.setItem(
          "apiCalls",
          Number(sessionStorage.getItem("apiCalls")) + 1
        );
        const extendResponse = await fetch(
          "https://audio-gen-server.vercel.app/api/extend-music",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              tags: style,
              title,
              continue_clip_id: uploadResult.clip_id,
              continue_at: uploadResult.duration,
            }),
          }
        );

        if (!extendResponse.ok) {
          throw new Error(`HTTP error! status: ${extendResponse.status}`);
        }

        const data = await extendResponse.json();
        const extendResult = JSON.parse(data);
        await localforage.setItem(
          `fileId-${extendResult.clips[0].id}`,
          selectedFile
        );

        newTracks = extendResult.clips.map((tr) => ({
          id: tr.id,
          title: title || "Untitled",
          tags: style,
          cdn_url: `https://cdn1.suno.ai/${tr.id}.mp3`,
          temp_url: `https://audiopipe.suno.ai/?item_id=${tr.id}`,
          image_url: `https://cdn2.suno.ai/image_${tr.id}.jpeg`,
          lyrics: "",
          status: "streaming",
          uploadedFile: `fileId-${extendResult.clips[0].id}`,
        }));
      } else {
        newTracks = await generateTrack(style);
        if(newTracks[0].error_message){
          setIsAlert(true);
          setAlertContent({title: "Error", message: newTracks[0].error_message});
          return;
        }
      }

      if (!newTracks || newTracks.length === 0) {
        throw new Error("No tracks were generated");
      }

      const streamingTracks = newTracks.filter(
        (track) => track.status === "streaming"
      );
      if (streamingTracks.length === 0) {
        throw new Error("No valid tracks were generated. Please try again.");
      }

      streamingTracks.forEach((t) => {
        t.title = title || "Untitled";
        t.tags = style;
      });

      await updateTracks(streamingTracks, requiredCredits);

      setIsAlert(true);
      setAlertContent({
        title: userPlan === "Starter" ? "Track Ready" : "Success",
        message:
          userPlan === "Starter"
            ? "Your track is ready! Upgrade for faster processing."
            : `Generated ${streamingTracks.length} track(s).`,
      });

      return streamingTracks;
    } catch (error) {
      console.error("[Client] Error generating track:", error);

      if(newTracks.error_message){
        setIsAlert(true);
        setAlertContent({
          title: newTracks.error_message.includes("artist name") ? "Copyright Music" : "Error",
          message: newTracks.error_message,
        });
      }else{
        console.log(newTracks)
        setIsAlert(true);
        setAlertContent({
          title: "Generation Error",
          message:
          userPlan === "Starter"
          ? "Error occurred. Upgrade for more reliable access."
          : error.message,
        });
      }
      throw error;
    } finally {
      setIsLoading(false);
      sessionStorage.setItem("apiCalls", 0);
    }
  };

  const handleNext = () => {
    if (currentTrack) {
      if (currentTrack.id === generatedTracks[generatedTracks.length - 1].id) {
        setCurrentTrack(generatedTracks[0]);
      } else {
        setCurrentTrack(
          generatedTracks[
            generatedTracks.findIndex((track) => track.id === currentTrack.id) +
              1
          ]
        );
      }
    }
  };

  const handlePrev = () => {
    if (currentTrack) {
      if (currentTrack.id === generatedTracks[0].id) {
        setCurrentTrack(generatedTracks[generatedTracks.length - 1]);
      } else {
        setCurrentTrack(
          generatedTracks[
            generatedTracks.findIndex((track) => track.id === currentTrack.id) -
              1
          ]
        );
      }
    }
  };

  const getRandomTrack = () => {
    const randomIndex = Math.floor(Math.random() * generatedTracks.length);
    if (randomIndex !== generatedTracks.findIndex((track) => track.id === currentTrack.id)) {
      return getRandomTrack();
    }
    return randomIndex;
  };

  const shuffleTrack = () => {
    setCurrentTrack(generatedTracks[getRandomTrack()]);
  };

  const handleLogin = async (userData) => {
    setUser(userData);
    setIsLoggedIn(true);
    setUsername(userData.displayName || userData.email);
    await fetchUserData(userData.uid);
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        setUser(null);
        setIsLoggedIn(false);
        setUsername("");
        setCredits(1);
        setGeneratedTracks([]);
        setCurrentTrack(null);
        audioPlayerRef.current.audio.current.pause();
        setIsPlaying(false);
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  const handleTutorialComplete = async () => {
    setShowTutorial(false);
    setShowConfetti(true);
    setHasCompletedTutorial(true);
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      try {
        await updateDoc(userDocRef, { hasCompletedTutorial: true });
      } catch (error) {
        console.error("Error updating tutorial completion status:", error);
      }
    }
  };

  const handleLoadingComplete = () => {
    setShowLoadingOverlay(false);
  };

  const handleStemExtraction = (track) => {
    if (!isLoggedIn) {
      setIsAlert(true);
      setAlertContent({
        title: "Login Required",
        message: "Please log in to extract stems.",
      });
      return;
    }
    setCurrentStemTrack(track);
    setIsStemModalOpen(true);
  };

  const getStemExtraction = async (fileUrl, params, mode) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      sessionStorage.setItem(
        "apiCalls",
        Number(sessionStorage.getItem("apiCalls")) + 1
      );
      let stemUpload = await handleStemUpload(blob);

      const stemId = stemUpload.id;

      if (stemUpload.status !== "success") {
        throw new Error("Stem upload failed");
      }
      sessionStorage.setItem(
        "apiCalls",
        Number(sessionStorage.getItem("apiCalls")) + 1
      );
      const stemSplit = await handleStemSplit(stemId, params);
      if (stemSplit.status !== "success") {
        throw new Error("Stem split failed");
      }

      let checkComplete = false;
      let stemCheck = null;
      while (!checkComplete) {
        try {
          sessionStorage.setItem(
            "apiCalls",
            Number(sessionStorage.getItem("apiCalls")) + 1
          );
          stemCheck = await handleStemCheck(stemId);

          if (
            stemCheck &&
            stemCheck.result &&
            stemCheck.result[`${stemId}`].split !== null
          ) {
            checkComplete = true;
          }
        } catch (error) {
          console.error("Error checking stem:", error);
        }

        // Wait for 5 seconds before the next check
        await new Promise((resolve) => setTimeout(resolve, 6000));
      }

      // Update Firestore
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, {
          credits: credits,
          apiCalls: increment(Number(sessionStorage.getItem("apiCalls"))),
          last_active: Date.now(),
        });
      }
      if (userPlan === "New Starter") {
        await delay(5000);
      }

      return stemCheck.result[stemId];
    } catch (error) {
      console.error("Stem extraction failed:", error);
      throw error;
    } finally {
      sessionStorage.setItem("apiCalls", 0);
    }
  };

  return (
    <Router>
      <AppContainer>
        <Background background={background} />
        {authLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              fontSize: "1.5rem",
              color: "#ffffff",
              backgroundColor: "#000000",
              position: "absolute",
              zIndex: "10000000",
              width: "100vw",
            }}
          >
            <img
              src="/Infinity@1x-1.0s-200px-200px.svg"
              alt="Loading..."
              style={{
                width: "250px",
                height: "250px",
              }}
            />
          </div>
        ) : (
          <>
            {isOffline && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: "orange",
                  color: "white",
                  textAlign: "center",
                  padding: "10px",
                  zIndex: 9999,
                }}
              >
                You are currently offline. Some features may be limited.
              </div>
            )}
            <ConfettiComponent
              showConfetti={showConfetti}
              setShowConfetti={setShowConfetti}
            />
            <Navbar
              onBackgroundChange={handleBackgroundChange}
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              username={username}
              setUsername={setUsername}
              credits={credits}
              onLogin={handleLogin}
              onLogout={handleLogout}
              setShowTCModal={setShowTCModal}
              isAdmin={user?.email === "admin@harmedforces.com" ? true : false}
              customerId={customerId}
              setIsAlert={setIsAlert}
              setAlertContent={setAlertContent}
            />
            <Routes>
              <Route
                path="/"
                element={
                  isLoggedIn ? (
                    <ContentContainer>
                      {media.matches && (
                        <MusicStyleSelector3D
                          onGenerateTrack={handleGenerateTrack}
                          isOffline={isOffline}
                          uploadMusic={uploadMusicWithApi}
                          extendMusic={extendMusicWithApi}
                          getGeneratedMusic={getGeneratedMusic}
                          fetchGeneratedMusic={fetchGeneratedMusic}
                          updateTracks={updateTracks}
                          setIsAlert={setIsAlert}
                          setAlertContent={setAlertContent}
                          isLoading={isLoading}
                          isLoggedIn={isLoggedIn}
                          credits={credits}
                          fillInput={fillInput}
                          setFillInput={setFillInput}
                        />
                      )}
                      <GeneratedTracks3D
                        audioPlayerRef={audioPlayerRef}
                        tracks={generatedTracks}
                        currentPlayingTrack={currentTrack}
                        setCurrentTrack={setCurrentTrack}
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying}
                        user={user}
                        credits={credits}
                        setCredits={setCredits}
                        newTracks={newTracks}
                        setNewTracks={setNewTracks}
                        setIsAlert={setIsAlert}
                        setAlertContent={setAlertContent}
                        isLoggedIn={isLoggedIn}
                        checkCDNAvailability={checkCDNAvailability}
                        onStemExtraction={handleStemExtraction}
                        showAudioLoader={showAudioLoader}
                        setShowAudioLoader={setShowAudioLoader}
                        handleGenerateTrack={handleGenerateTrack}
                        setFillInput={setFillInput}
                        setAllTracksLoaded={setAllTracksLoaded}
                      />
                      {media.matches && (
                        <Canvas
                          camera={{ position: [0, 0, 13], fov: 25 }}
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: -1,
                          }}
                        >
                          <ambientLight intensity={Math.PI} />
                          <Physics
                            interpolate
                            gravity={[0, -40, 0]}
                            timeStep={1 / 60}
                          >
                            <Band key={username} name={username} />
                          </Physics>
                          <Environment background={false} blur={0.75}>
                            <Lightformer
                              intensity={2}
                              color="white"
                              position={[0, -1, 5]}
                              rotation={[0, 0, Math.PI / 3]}
                              scale={[100, 0.1, 1]}
                            />
                            <Lightformer
                              intensity={3}
                              color="white"
                              position={[-1, -1, 1]}
                              rotation={[0, 0, Math.PI / 3]}
                              scale={[100, 0.1, 1]}
                            />
                            <Lightformer
                              intensity={3}
                              color="white"
                              position={[1, 1, 1]}
                              rotation={[0, 0, Math.PI / 3]}
                              scale={[100, 0.1, 1]}
                            />
                            <Lightformer
                              intensity={10}
                              color="white"
                              position={[-10, 0, 14]}
                              rotation={[0, Math.PI / 2, Math.PI / 3]}
                              scale={[100, 10, 1]}
                            />
                          </Environment>
                        </Canvas>
                      )}
                      {isLoggedIn && showTutorial && !hasCompletedTutorial && (
                        <Tutorial
                          step={tutorialStep}
                          setStep={setTutorialStep}
                          onComplete={handleTutorialComplete}
                          generateTrackExist={generatedTracks.length > 0}
                          setGeneratedTracks={setGeneratedTracks}
                        />
                      )}
                    </ContentContainer>
                  ) : (
                    <LandingPage
                      onGenerateTrack={handleGenerateTrack}
                      credits={credits}
                      setCredits={setCredits}
                      setIsAlert={setIsAlert}
                      setAlertContent={setAlertContent}
                      isLoading={isLoading}
                    />
                  )
                }
              />
              <Route
                path="/subscription"
                element={
                  isLoggedIn ? (
                    <SubscriptionPage
                      userId={user.uid}
                      credits={credits}
                      setCredits={setCredits}
                      isOffline={isOffline}
                    />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route path="/refer" element={<ReferPage userId={user?.uid} />} />
            <Route path="/refer/:referralId" element={<LandingPage isLoggedIn={isLoggedIn}/>}/>
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/listen/:id" element={<SharedTrackPage />} />
              <Route path="/admin" element={<AdminDashboard />} />
            </Routes>
            {!authLoading &&
              isLoggedIn &&
              !isStemModalOpen &&
              !window.location.pathname.includes("/admin") &&
              !window.location.pathname.includes("/listen") && (
                <AudioPlayer3D
                  audioPlayerRef={audioPlayerRef}
                  currentTrack={currentTrack}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                  isPlaying={isPlaying}
                  setIsPlaying={setIsPlaying}
                  newTracks={newTracks}
                  setNewTracks={setNewTracks}
                  shuffleTrack={shuffleTrack}
                />
              )}
            <Alert
              isAlert={isAlert}
              setIsAlert={setIsAlert}
              alertContent={alertContent}
            />
            {isStemModalOpen && (
              <StemExtractionModal
                isOpen={isStemModalOpen}
                onClose={() => setIsStemModalOpen(false)}
                track={currentStemTrack}
                userCredits={credits}
                setUserCredits={setCredits}
                getStemExtraction={getStemExtraction}
                userPlan={userPlan}
                delay={delay}
              />
            )}
            {showLoadingOverlay && (
              <LoadingOverlay onComplete={handleLoadingComplete} />
            )}
            {showTCModal && <TCModal onClose={() => setShowTCModal(false)} />}
          </>
        )}
      </AppContainer>
    </Router>
  );
};

export default App;