import * as THREE from 'three';
import React, { useRef, useState, useMemo, useEffect } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { useGLTF, useTexture } from '@react-three/drei';
import { BallCollider, CuboidCollider, RigidBody, useRopeJoint, useSphericalJoint } from '@react-three/rapier';
import BadgeText from './BadgeText';

function Band({ name, maxSpeed = 50, minSpeed = 10 }) {
  console.log('[Band] Rendering with name:', name);

  const band = useRef(), fixed = useRef(), j1 = useRef(), j2 = useRef(), j3 = useRef(), card = useRef();
  const vec = new THREE.Vector3(), ang = new THREE.Vector3(), rot = new THREE.Vector3(), dir = new THREE.Vector3();
  const segmentProps = { type: 'dynamic', canSleep: true, colliders: false, angularDamping: 2, linearDamping: 2 };
  const { nodes, materials } = useGLTF('https://assets.vercel.com/image/upload/contentful/image/e5382hct74si/5huRVDzcoDwnbgrKUo1Lzs/53b6dd7d6b4ffcdbd338fa60265949e1/tag.glb');
  const texture = useTexture('/band.png');
  const metalTexture = useTexture('/textures/everytexture.com-stock-metal-texture-00139.jpg');
  const { width, height } = useThree((state) => state.size);
  const [curve] = useState(() => new THREE.CatmullRomCurve3([new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()]));
  const [dragged, setDragged] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const cardMaterial = useMemo(() => new THREE.MeshPhysicalMaterial({
    color: 'black', // Base color remains black
    map: metalTexture, // Overlay the texture
    roughness: 0.3, // Adjusted for subtle texture visibility
    metalness: 1, // Emphasize metallic properties
    clearcoat: 1,
    clearcoatRoughness: 0.05, // Sharper reflections
  }), [metalTexture]);

  const accentMaterial = useMemo(() => new THREE.MeshStandardMaterial({
    color: '#f3b718',
    roughness: 0.3,
    metalness: 0.7,
  }), []);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useRopeJoint(fixed, j1, [[0, 0, 0], [0, 0, 0], 1]);
  useRopeJoint(j1, j2, [[0, 0, 0], [0, 0, 0], 1]);
  useRopeJoint(j2, j3, [[0, 0, 0], [0, 0, 0], 1]);
  useSphericalJoint(j3, card, [[0, 0, 0], [0, 1.45, 0]]);

  useFrame((state, delta) => {
    if (dragged && card.current) {
      vec.set(state.pointer.x, state.pointer.y, 0.5).unproject(state.camera);
      dir.copy(vec).sub(state.camera.position).normalize();
      vec.add(dir.multiplyScalar(state.camera.position.length()));
      [card, j1, j2, j3, fixed].forEach((ref) => ref.current?.wakeUp?.());
      card.current.setNextKinematicTranslation?.({ x: vec.x - dragged.x, y: vec.y - dragged.y, z: vec.z - dragged.z });
    }

    if (fixed.current && !isMobile) {
      [j1, j2].forEach((ref) => {
        if (ref.current) {
          if (!ref.current.lerped) {
            ref.current.lerped = new THREE.Vector3();
          }
          if (ref.current.translation) {
            ref.current.lerped.copy(ref.current.translation());
            const clampedDistance = Math.max(0.1, Math.min(1, ref.current.lerped.distanceTo(ref.current.translation())));
            ref.current.lerped.lerp(ref.current.translation(), delta * (minSpeed + clampedDistance * (maxSpeed - minSpeed)));
          }
        }
      });

      if (j3.current && j3.current.translation &&
          j2.current && j2.current.lerped &&
          j1.current && j1.current.lerped &&
          fixed.current && fixed.current.translation) {
        curve.points[0].copy(j3.current.translation());
        curve.points[1].copy(j2.current.lerped);
        curve.points[2].copy(j1.current.lerped);
        curve.points[3].copy(fixed.current.translation());
      }

      if (band.current && band.current.geometry && band.current.geometry.setPoints) {
        band.current.geometry.setPoints(curve.getPoints(32));
      }

      if (card.current && card.current.angvel && card.current.rotation && card.current.setAngvel) {
        ang.copy(card.current.angvel());
        rot.copy(card.current.rotation());
        card.current.setAngvel({ x: ang.x, y: ang.y - rot.y * 0.25, z: ang.z });
      }
    }

    // Add subtle motion to the card for subconscious engagement
    if (card.current) {
      const time = state.clock.getElapsedTime();
      
      // Subtle oscillating rotation
      if (card.current.rotation) {
        card.current.rotation.y = Math.sin(time * 0.1) * 0.05;
        card.current.rotation.x = Math.cos(time * 0.1) * 0.02;
      }
      
      // Breathing scale effect
      const scale = 1 + Math.sin(time * 0.5) * 0.02;
      if (card.current.scale) {
        card.current.scale.set(scale, scale, scale);
      }
      
      // Micro-translation
      if (card.current.position) {
        card.current.position.x += Math.sin(time * 0.3) * 0.005;
        card.current.position.y += Math.cos(time * 0.4) * 0.005;
        card.current.position.z += Math.sin(time * 0.2) * 0.005;
      }
    }
  });

  curve.curveType = 'chordal';
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

  return (
    <>
      <ambientLight intensity={0.5} />
      <pointLight position={[5, 5, 5]} intensity={1} />
      <group position={[0, 4, 0]}>
        <RigidBody ref={fixed} {...segmentProps} type="fixed" />
        <RigidBody position={[0.5, 0, 0]} ref={j1} {...segmentProps}>
          <BallCollider args={[0.1]} />
        </RigidBody>
        <RigidBody position={[1, 0, 0]} ref={j2} {...segmentProps}>
          <BallCollider args={[0.1]} />
        </RigidBody>
        <RigidBody position={[1.5, 0, 0]} ref={j3} {...segmentProps}>
          <BallCollider args={[0.1]} />
        </RigidBody>
        <RigidBody position={[2, 0, 0]} ref={card} {...segmentProps} type={dragged ? 'kinematicPosition' : 'dynamic'}>
          <CuboidCollider args={[0.8, 1.125, 0.01]} />
          <group
            scale={2.25}
            position={[0, -1.2, -0.05]}
            onPointerUp={(e) => { e.target.releasePointerCapture(e.pointerId); setDragged(false); }}
            onPointerDown={(e) => { 
              e.target.setPointerCapture(e.pointerId); 
              if (card.current && card.current.translation) {
                setDragged(new THREE.Vector3().copy(e.point).sub(vec.copy(card.current.translation())));
              }
            }}
          >
            <mesh geometry={nodes.card.geometry} material={cardMaterial} />
            <mesh geometry={nodes.clip.geometry} material={accentMaterial} />
            <mesh geometry={nodes.clamp.geometry} material={accentMaterial} />
            <BadgeText username={name} />
          </group>
        </RigidBody>
      </group>
      {!isMobile && (
        <mesh ref={band}>
          <meshLineGeometry />
          <meshLineMaterial color="#f3b718" depthTest={false} resolution={[width, height]} useMap map={texture} repeat={[-3, 1]} lineWidth={1} />
        </mesh>
      )}
    </>
  );
}

export default Band;
