import React, { useState, useEffect, useMemo } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
"pk_live_51Pj2IJHxahnbQJMdTr6xK59tVfM1Cm2BXorBXGpygWMXzk181OrZBfcbiokQGXzLxgla0rVqLGWKITpxAiNN1eGc00eOnEvgpF"
);

const SubscriptionPage = ({ userId, credits, setCredits, isOffline }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPlanId, setLoadingPlanId] = useState(null);
  const [creditModal, setCreditModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [billingPeriod, setBillingPeriod] = useState("monthly");

  const plans = [
    {
      name: "Starter",
      priceId: "price_1Poo48HxahnbQJMdBo2Z5u13",
      mode: "subscription",
      billingPeriod: "monthly",
      price: 17.99,
      credits: 100,
      popular: false,
      referralBonus : 50,
      features: [
        "100 generations (200 songs) per month",
        "Shared generation queue",
      ],
    },
    {
      name: "Starter",
      priceId: "price_1Poo69HxahnbQJMdmY8uPOVq",
      mode: "subscription",
      billingPeriod: "yearly",
      price: 179.99,
      credits: 1300,
      popular: false,
      referralBonus : 50,
      features: [
        "100 generations (200 songs) per month",
        "Shared generation queue",
      ],
    },
    {
      name: "Pro",
      priceId: "price_1Poo7eHxahnbQJMdXSNtOFcl",
      mode: "subscription",
      billingPeriod: "monthly",
      price: 49.99,
      credits: 300,
      popular: true,
      referralBonus : 100,
      features: [
        "300 generations (600 songs) per month",
        "Priority generation queue",
      ],
    },
    {
      name: "Pro",
      priceId: "price_1Poo8gHxahnbQJMdeGgNowtI",
      mode: "subscription",
      billingPeriod: "yearly",
      price: 499.99,
      credits: 3900,
      popular: true,
      referralBonus : 100,
      features: [
        "300 generations (600 songs) per month",
        "Priority generation queue",
      ],
    },
    {
      name: "Ultimate",
      priceId: "price_1Poo9uHxahnbQJMdoUOy09uZ",
      mode: "subscription",
      billingPeriod: "monthly",
      price: 89.99,
      credits: 600,
      popular: false,
      referralBonus : 100,
      features: [
        "600 generations (1200 songs) per month",
        "Priority generation queue",
      ],
    },
    {
      name: "Ultimate",
      priceId: "price_1PooBFHxahnbQJMdW4l2fVlz",
      mode: "subscription",
      billingPeriod: "yearly",
      price: 899.99,
      credits: 7800,
      popular: false,
      referralBonus : 100,
      features: [
        "600 generations (1200 songs) per month",
        "Priority generation queue",
      ],
    },
  ];

  const filteredPlans = plans.filter(
    (plan) => plan.billingPeriod === billingPeriod
  );

  const topUpOptions = useMemo(
    () => [
      {
        id: 1,
        priceId: "price_1PooCdHxahnbQJMdHHtWrbOW",
        mode: "payment",
        credits: 50,
        price: 9.99,
        popular: false,
        referralBonus : 50,
      },
      {
        id: 2,
        priceId: "price_1PooDWHxahnbQJMd8Z3k450b",
        mode: "payment",
        credits: 150,
        price: 27.99,
        popular: true,
        referralBonus : 50,
      },
      {
        id: 3,
        priceId: "price_1PooEaHxahnbQJMdNCLJ9Q9R",
        mode: "payment",
        credits: 400,
        price: 69.99,
        popular: false,
        referralBonus : 50,
      },
    ],
    []
  );

  const serviceCosts = [
    { service: "Song Generation", cost: "1 credit" },
    { service: "Preview Stem", cost: "1 credit" },
    { service: "Remix", cost: "2 credits" },
    { service: "Stem Extraction", cost: "30 credits" },
  ];


  const redirectToCheckout = async (
    priceId,
    mode,
    planName,
    credits,
    billingPeriod,
    referralBonus

  ) => {
    setIsLoading(true);
    setLoadingPlanId(priceId);
    const stripe = await stripePromise;

    const response = await fetch(
      "https://audio-gen-server.vercel.app/api/create-checkout-session",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId,
          priceId,
          mode,
          planName,
          credits,
          billingPeriod,
          referralBonus
        }),
      }
    );

    const { id: sessionId } = await response.json();
    const userDocRef = doc(db, "users", userId);

    // Fetch current user document
    await updateDoc(userDocRef, {
      session_id: sessionId,
    });

    const { error } = await stripe.redirectToCheckout({ sessionId });

    if (error) {
      console.error("Error redirecting to checkout:", error);
      setIsLoading(false);
      setLoadingPlanId(null);
    }
  };

  useEffect(() => {
    setSelectedPlan(topUpOptions[1]);
  }, [topUpOptions]);

  const baseStyle = {
    backgroundColor: "#1a1a1a",
    color: "white",
    padding: "20px",
    borderRadius: "10px",
    marginBottom: "20px",
    border: "1px solid #333",
    transition: "transform 0.3s ease-in-out",
  };

  const popularStyle = {
    ...baseStyle,
    border: "2px solid #ffd700",
    transform: "scale(1.05)",
  };

  const buttonStyle = (planName) => ({
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: planName === "Starter" || planName === "Ultimate" ? "#3a3a3a" : "#ffd700",
    color: planName === "Starter" || planName === "Ultimate" ? "white" : "black",
    border: "none",
    borderRadius: "20px",
    cursor: "pointer",
    fontWeight: "bold",
    transition: "background-color 0.3s",
    ':hover': {
      backgroundColor: planName === "Starter" || planName === "Ultimate" ? "#f3b718" : "#ffd700",
      color: "black",
    }
  });

  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#2c2c2c",
    padding: "30px",
    borderRadius: "20px",
    boxShadow: "0 4px 20px rgba(255, 255, 255, 0.2)",
    zIndex: 1000,
    color: "white",
    width: "90%",
    maxWidth: "350px",
    maxHeight: "80vh",
    overflowY: "auto",
  };

  const creditOptionStyle = (isSelected, isPopular) => ({
    backgroundColor: isSelected ? "#ffd700" : isPopular ? "#3a3a3a" : "#2c2c2c",
    border: isPopular ? "2px solid #ffd700" : "none",
    color: isSelected ? "black" : "white",
    padding: "15px",
    margin: "10px 0",
    width: "100%",
    textAlign: "left",
    cursor: "pointer",
    borderRadius: "10px",
    transition: "all 0.3s",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    transform: isPopular ? "scale(1.05)" : "scale(1)",
  });

  return (
    <div
      style={{
        padding: "80px 20px 100px",
        color: "white",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        minHeight: "100vh",
        boxSizing: "border-box",
        overflowY: "auto",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <h1 style={{ textAlign: "center", marginBottom: "30px" }}>
        Subscription Plans
      </h1>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <button
          style={{
            ...buttonStyle(),
            cursor : "pointer",
            backgroundColor:
              billingPeriod === "monthly" ? "#ffd700" : "transparent",
            marginRight: "10px",
          }}
          onClick={() => setBillingPeriod("monthly")}
        >
          Monthly
        </button>
        <button
          style={{
            ...buttonStyle(),
            cursor: "pointer",
            backgroundColor:
              billingPeriod === "yearly" ? "#ffd700" : "transparent",
            border: billingPeriod === "yearly" ? "none" : "2px solid #ffd700",
            color: billingPeriod === "yearly" ? "black" : "#ffd700",
          }}
          onClick={() => setBillingPeriod("yearly")}
        >
          Yearly (Save 16% + Bonus Credits)
        </button>
      </div>

      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {filteredPlans.map((plan, index) => (
          <div key={index} style={plan.popular ? popularStyle : baseStyle}>
            {plan.popular && (
              <div
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "-10px",
                  backgroundColor: "#ffd700",
                  color: "black",
                  padding: "5px 10px",
                  borderRadius: "15px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                MOST POPULAR
              </div>
            )}
            <h2 style={{ textAlign: "center" }}>{plan.name}</h2>
            <p
              style={{
                textAlign: "center",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              ${plan.price.toFixed(2)}/
              {billingPeriod === "yearly" ? "year" : "month"}
            </p>
            <p style={{ textAlign: "center" }}>
              {plan.credits}{' '}
              credits
              {billingPeriod === "yearly" && (
                <span style={{ color: "#ffd700" }}> (8.33% monthly bonus)</span>
              )}
            </p>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {plan.features.map((feature, i) => (
                <li key={i} style={{ margin: "10px 0" }}>
                  ✓ {feature}
                </li>
              ))}
            </ul>
            <button
              style={{
                ...buttonStyle(plan.name),
                width: "100%",
                marginTop: "20px",
                backgroundColor: plan.popular ? "#ffd700" : "#3a3a3a",
              }}
              onClick={() => {
                redirectToCheckout(
                  plan.priceId,
                  plan.mode,
                  plan.name,
                  plan.credits,
                  plan.billingPeriod,
                  plan.referralBonus
                );
              }}
              disabled={isLoading && loadingPlanId === plan.priceId}
            >
              {isLoading && loadingPlanId === plan.priceId ? "Processing..." : "Subscribe"}
            </button>
          </div>
        ))}
      </div>

      <div style={{ ...baseStyle, maxWidth: "600px", margin: "30px auto" }}>
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Service Costs
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          {serviceCosts.map((service, index) => (
            <div
              key={index}
              style={{
                backgroundColor: "#2c2c2c",
                padding: "15px",
                borderRadius: "10px",
                margin: "10px",
                textAlign: "center",
                minWidth: "150px",
              }}
            >
              <h3 style={{ marginBottom: "10px" }}>{service.service}</h3>
              <p style={{ fontWeight: "bold", color: "#ffd700" }}>
                {service.cost}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          ...baseStyle,
          textAlign: "center",
          maxWidth: "400px",
          margin: "30px auto",
        }}
      >
        <h2 style={{ marginBottom: "20px" }}>Need more credits?</h2>
        <p style={{ fontSize: "18px", marginBottom: "20px" }}>
          Current credits:{" "}
          <span style={{ color: "#ffd700", fontWeight: "bold" }}>
            {credits}
          </span>
        </p>
        <button
          style={{ ...buttonStyle(), width: "200px" }}
          onClick={() => setCreditModal(true)}
        >
          Buy More Credits
        </button>
      </div>

      {creditModal && (
        <div style={modalStyle}>
          <button
            style={{
              position: "absolute",
              top: "10px",
              right: "15px",
              background: "none",
              border: "none",
              color: "white",
              fontSize: "24px",
              cursor: "pointer",
            }}
            onClick={() => setCreditModal(false)}
          >
            ×
          </button>
          <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
            Buy More Credits
          </h2>
          <p style={{ textAlign: "center", marginBottom: "20px" }}>
            Select amount of credits
          </p>
          {topUpOptions.map((pkg) => (
            <button
              key={pkg.credits}
              style={creditOptionStyle(selectedPlan.id === pkg.id, pkg.popular)}
              onClick={() => {
                setSelectedPlan(pkg);
              }}
            >
              <span>{pkg.credits} credits</span>
              <span>${pkg.price.toFixed(2)}</span>
              {pkg.popular && (
                <span style={{ fontSize: "12px", color: "#ffd700" }}>
                  BEST VALUE
                </span>
              )}
            </button>
          ))}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <button
              style={{
                ...buttonStyle(),
                backgroundColor: "transparent",
                color: "white",
                border: "1px solid white",
              }}
              onClick={() => setCreditModal(false)}
            >
              Back
            </button>
            <button
              style={{
                ...buttonStyle(),
                backgroundColor: selectedPlan ? "#ffd700" : "#cccccc",
                color: "black",
              }}
              onClick={() =>
                redirectToCheckout(
                  selectedPlan.priceId,
                  selectedPlan.mode,
                  "",
                  selectedPlan.credits,
                  "",
                  selectedPlan.referralBonus
                )
              }
              disabled={isLoading}
            >
              {isLoading ? "Processing..." : "Purchase"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPage;