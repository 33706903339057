import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const TutorialOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-out;
`;

const TutorialContent = styled.div`
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  text-align: center;
  color: #ffffff;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 10px;
  font-size: 1rem;
  background-color: #f3b718;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d9a116;
  }
`;

const Highlight = styled.div`
  position: absolute;
  border: 2px solid #f3b718;
  border-radius: 5px;
  animation: ${pulse} 1.5s infinite ease-in-out;
  pointer-events: none;
  z-index: 1001;
`;

const tutorialSteps = [
  {
    title: 'Welcome to SampleGenerator.xyz!',
    content: "Let's walk through how to create your first AI-generated track.",
    highlight: null,
  },
  {
    title: 'Step 1: Choose a Style',
    content: 'Enter a music style or genre in the input field. Be creative! Try combining different genres or describing the mood you want.',
    highlight: 'textarea.styledTextArea',
  },
  {
    title: 'Step 2: Generate',
    content: 'Click the "Create" button to generate your custom track. Our AI will use your description to create a unique piece of music.',
    highlight: 'button.CreateButton',
  },
  {
    title: 'Step 3: Listen and Download',
    content: 'Once generated, you can play your track and download it. Click the play button to listen, and use the download button to save your creation.',
    highlight: 'div.TrackItem',
  },
  {
    title: 'Step 4: Get More Credits',
    content: 'Running low on credits? Visit the subscription page to get more and keep creating! Upgrade your plan for unlimited generations.',
    highlight: 'a.SubscriptionButton',
  },
  {
    title: 'Step 5: Rerun Generation',
    content: 'Not quite what you wanted? Use the rerun button to generate a new track with the same prompts. It\'s a great way to explore different interpretations of your style.',
    highlight: 'button[data-tooltip-id^="rerun-tooltip"]',
  },
  {
    title: 'Step 6: Download Your Track',
    content: 'Love what you hear? Click the download button to save your generated track to your device. You can use it in your projects or share it with friends.',
    highlight: 'button[data-tooltip-id^="download-tooltip"]',
  },
  {
    title: 'Step 7: Share Your Creation',
    content: 'Proud of your AI-generated track? Use the share button to show it off on social media or send it directly to friends and collaborators.',
    highlight: 'button[data-tooltip-id^="share-tooltip"]',
  },
  {
    title: 'Step 8: Explore Stem Extraction',
    content: 'Want more control? Try extracting stems from your generated track. This feature separates your track into individual instruments, perfect for remixing or further editing.',
    highlight: 'button.StemExtractionButton',
  },
  {
    title: 'Step 9: Upload Your Own Audio',
    content: 'Have a sample or track you want to use as a base? Click here to upload your own audio file. Our AI can use it as inspiration or a starting point for generation.',
    highlight: 'button.UploadButton',
  },
  {
    title: 'Step 10: Record Audio Input',
    content: 'Got a melody in your head? Use the recording feature to capture your ideas directly. Hum, sing, or play an instrument - our AI can work with your recorded input.',
    highlight: 'button.RecordingButton',
  },
  {
    title: 'Step 11: Manage Your Account',
    content: 'Access your account settings, view your generation history, and manage your subscription from the user menu. Keep track of your creations and usage here.',
    highlight: 'button.UserMenu',
  },
];

const Tutorial = ({ step, setStep, onComplete , generateTrackExist , setGeneratedTracks }) => {
  const [highlightPosition, setHighlightPosition] = useState(null);
  const [highlightContent, setHighlightContent] = useState('');
  const [selectedElement, setSelectedElement] = useState(null);

  useEffect(() =>{
    if(!generateTrackExist){
      setGeneratedTracks([{
        "lyrics": "",
        "cdn_url": "https://cdn1.suno.ai/78db330d-827d-44a5-a353-8ef76dcaa264.mp3",
        "image_url": "https://cdn2.suno.ai/image_78db330d-827d-44a5-a353-8ef76dcaa264.jpeg",
        "title": "Sample Track",
        "id": "78db330d-827d-44a5-a353-8ef76dcaa264",
        "temp_url": "https://audiopipe.suno.ai/?item_id=78db330d-827d-44a5-a353-8ef76dcaa264",
        "tags": "Garage Rock, J-Rock"
    }]);
    }
  } , [setGeneratedTracks , generateTrackExist])


  useEffect(() => {
    const currentStep = tutorialSteps[step];

    if (currentStep.highlight) {
      const element = document.querySelector(currentStep.highlight);
      if (element) {
        setSelectedElement(element);
        const rect = element.getBoundingClientRect();
        const padding = 10; // Increased padding for better visibility
        setHighlightPosition({
          top: rect.top - padding / 2,
          left: rect.left - padding / 2,
          width: rect.width + padding,
          height: rect.height + padding,
          color : "white"
        });
        setHighlightContent(element.outerHTML);
        element.style.opacity = '0.5'; // Changed to partial opacity for visibility
      } else {
        console.warn(`Element not found for selector: ${currentStep.highlight}`);
        setHighlightPosition(null);
        setHighlightContent('');
      }
    } else {
      setHighlightPosition(null);
      setHighlightContent('');
    }

    // Cleanup function to restore element visibility
    return () => {
      if (selectedElement) {
        selectedElement.style.opacity = '1';
      }
    };
  }, [step]);

  const handleNext = () => {
    if (step < tutorialSteps.length - 1) {
      setStep(step + 1);
    } else {
      if (selectedElement) {
        selectedElement.style.opacity = '1';
      }
      onComplete();

    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const currentStep = tutorialSteps[step];

  return (
    <TutorialOverlay>
      <TutorialContent>
        <h2>{currentStep.title}</h2>
        <p>{currentStep.content}</p>
        <div>
          {step > 0 && (
            <Button onClick={handleBack}>Back</Button>
          )}
          <Button onClick={handleNext}>
            {step < tutorialSteps.length - 1 ? 'Next' : 'Finish'}
          </Button>
        </div>
      </TutorialContent>
      {highlightPosition && (
        <Highlight
          style={highlightPosition}
          dangerouslySetInnerHTML={{ __html: highlightContent }}
        />
      )}
    </TutorialOverlay>
  );
};

export default Tutorial;