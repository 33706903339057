import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const TutorialOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-out;
`;

const TutorialContent = styled.div`
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  text-align: center;
  color: #ffffff;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 10px;
  font-size: 1rem;
  background-color: #f3b718;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d9a116;
  }
`;

const Highlight = styled.div`
  position: absolute;
  border: 2px solid #f3b718;
  border-radius: 5px;
  animation: ${pulse} 1.5s infinite ease-in-out;
  pointer-events: none;
  height : fit-content;
  color : white;
`;


const onboardingsteps = [
  {
    content: "Welcome to Stem Extraction! Let's walk through the process.",
    highlight: null,
  },
  {
    content:  "Step 1: Select the stems you want to extract from the list on the left.",
    highlight: 'div.StemItem',
  },
  {
    content: "Step 2: Choose between Preview mode (1 Credit) or Full Extraction (uses credits).",
    highlight: 'button.stemButton',
  },
  {
    content: "Step 3: Listen to your extracted stems and download them if you're satisfied!",
    highlight: 'null',
  },
];

const Onboarding = ({ step, setStep, onComplete }) => {
  const [highlightPosition, setHighlightPosition] = useState(null);
  const [highlightContent , setHighlightContent] = useState('');
  const [selectedElement , setSelectedElement] = useState(null)

  useEffect(() => {
    setStep(0)
  } , [])

  useEffect(() => {
    if(selectedElement){selectedElement.style.opacity = "1"}
    const currentStep = onboardingsteps[step];
    if (currentStep.highlight) {
      const element = document.querySelector(currentStep.highlight);
      if (element) {
        setSelectedElement(element)
        const rect = element.getBoundingClientRect();
        setHighlightPosition({
          top: step!==2 ?  rect.top : rect.top + 110,
          left: rect.left - 5,
          width: rect.width + 10,
          height : rect.height
        });
        setHighlightContent('')
          setHighlightContent(element.outerHTML)
          element.style.opacity = '0';

      } else {
        console.warn(`Element not found for selector: ${currentStep.highlight}`);
        setHighlightPosition(null);
        setHighlightContent('')
      }
    } else {
      setHighlightPosition(null);
      setHighlightContent('')
    }
  }, [step , selectedElement]);

  const handleNext = () => {
    if (step < onboardingsteps.length - 1) {
      setStep(step + 1);
    } else {
      // selectedElement.style.opacity = "1"
      onComplete();
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const currentStep = onboardingsteps[step];

  return (
    <TutorialOverlay>
      <TutorialContent>
        <h3>{currentStep.content}</h3>
        <div>
          {step > 0 && (
            <Button onClick={handleBack}>Back</Button>
          )}
          <Button onClick={handleNext}>
            {step < onboardingsteps.length - 1 ? 'Next' : 'Finish'}
          </Button>
        </div>
      </TutorialContent>
      {highlightPosition && <Highlight style={highlightPosition}
       dangerouslySetInnerHTML={{ __html: highlightContent }}
      />}
    </TutorialOverlay>
  );
};

export default Onboarding;
