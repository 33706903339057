// src/data/genres.js

// Define the list of all genres and their sub-genres
export const allTags = [
  "K-Punk", "Post-Punk", "Gothic Rock", "New Wave", "No Wave",
  "Jazz", "Smooth Jazz", "Bebop", "Swing", "Free Jazz", "Jazz Fusion", "Acid Jazz",
  "Classic Rock", "Psychedelic Rock", "Hard Rock", "Progressive Rock", "Garage Rock",
  "Hip Hop", "Trap", "Boom Bap", "Lo-fi", "Grime", "Drill", "G-Funk", "Crunk",
  "Electronic", "House", "Techno", "Dubstep", "Ambient", "Trance", "Electro", "Breakbeat", "IDM", "Glitch",
  "Pop", "Synth-pop", "Electropop", "Art Pop", "Dream Pop", "Baroque Pop",
  "R&B", "Contemporary R&B", "Neo Soul", "Quiet Storm", "Funk Soul", "Blue-Eyed Soul",
  "Country", "Bluegrass", "Country Pop", "Alternative Country", "Honky Tonk", "Outlaw Country",
  "Blues", "Delta Blues", "Chicago Blues", "Electric Blues", "Blues Rock", "Piedmont Blues",
  "Funk", "P-Funk", "Funk Rock", "Afrobeat", "Go-go", "Funk Metal",
  "Soul", "Motown", "Northern Soul", "Southern Soul", "Philly Soul",
  "Reggae", "Dancehall", "Dub", "Roots Reggae", "Lovers Rock", "Reggaeton",
  "Metal", "Heavy Metal", "Thrash Metal", "Black Metal", "Doom Metal", "Death Metal", "Nu Metal", "Power Metal", "Symphonic Metal",
  "Indie", "Indie Pop", "Indie Rock", "Indie Folk", "Indie Electronica", "Indie Dance",
  "Punjabi", "Bhangra", "Punjabi Pop", "Punjabi Folk", "Punjabi Rap", "Sufi",
  "Bollywood", "Desi Hip Hop", "Indi-Pop",
  "Latin", "Reggaeton", "Salsa", "Bachata", "Tango", "Merengue", "Cumbia", "Bossa Nova",
  "World", "Afro-Cuban", "Afrobeat", "Klezmer", "Soca", "Zouk", "Raï", "Highlife", "Fado",
  "Folk", "Contemporary Folk", "Folk Rock", "Celtic", "Appalachian", "Nordic Folk", "Folk Metal",
  "Classical", "Baroque", "Romantic", "Contemporary Classical", "Minimalism", "Avant-Garde",
  "Gospel", "Spiritual", "Christian Rock", "Christian Hip Hop", "Southern Gospel",
  "J-Pop", "K-Pop", "C-Pop", "J-Rock", "K-Rock", "Enka",
  "New Age", "Meditative", "Healing", "Ambient", "Chillout", "Downtempo",
  "Experimental", "Noise", "Drone", "Avant-Garde", "Industrial",
  "Traditional", "Tuvan Throat Singing", "Flamenco", "Carnatic", "Qawwali", "Mariachi",
  "Vaporwave", "Chillwave", "Synthwave", "Witch House", "Afrofuturism",
  "UK Drill", "Trap Metal", "Emo Rap", "Cloud Rap",
  "Math Rock", "Post-Rock", "Shoegaze", "Dreampop", "Noise Pop",
  "Breakcore", "Hardvapour", "Nightcore", "Footwork", "Juke",
  "Neurofunk", "Liquid Funk", "Jump Up", "Techstep", "Darkstep",
  "Future Garage", "UK Funky", "Bassline", "Speed Garage", "2-Step",
  "Psytrance", "Goa Trance", "Forest Psytrance", "Dark Psytrance", "Full-On",
  "Dungeon Synth", "Dark Ambient", "Drone Metal", "Blackgaze", "Atmospheric Black Metal",
  "Zeuhl", "Rock In Opposition", "Avant-Prog", "Canterbury Scene", "Krautrock",
  "Neue Deutsche Härte", "EBM", "Futurepop", "Aggrotech", "Dark Electro",
  "Folktronica", "Glitch Pop", "Wonky", "Glitch Hop", "Neurohop",
  "Kawaii Metal", "Visual Kei", "Japanoise", "Shibuya-kei", "City Pop"
];

// Define the map of genres and their related sub-genres
export const genreMap = {
  "K-Punk": ["Post-Punk", "Gothic Rock", "New Wave", "No Wave", "Coldwave", "Minimal Wave"],
  "Jazz": ["Smooth Jazz", "Bebop", "Swing", "Free Jazz", "Jazz Fusion", "Acid Jazz", "Nu Jazz", "Gypsy Jazz", "Cool Jazz", "Modal Jazz"],
  "Classic Rock": ["Psychedelic Rock", "Hard Rock", "Progressive Rock", "Garage Rock", "Southern Rock", "Art Rock", "Glam Rock", "Pub Rock"],
  "Hip Hop": ["Trap", "Boom Bap", "Lo-fi", "Desi Hip Hop", "Grime", "Drill", "G-Funk", "Crunk", "Alternative Hip Hop", "Jazz Rap", "Conscious Hip Hop", "Horrorcore"],
  "Trap": ["Southern Hip Hop", "Cloud Rap", "Mumble Rap", "Phonk", "Trap Metal", "Latin Trap"],
  "Boom Bap": ["East Coast Hip Hop", "Conscious Hip Hop", "Jazz Rap", "Political Hip Hop", "Hardcore Hip Hop"],
  "Electronic": ["House", "Techno", "Dubstep", "Ambient", "Trance", "Electro", "Breakbeat", "IDM", "Glitch", "Synthwave", "Vaporwave", "Chillwave"],
  "House": ["Deep House", "Tech House", "Acid House", "Progressive House", "Tropical House", "Future House", "Electro House", "Microhouse"],
  "Pop": ["Synth-pop", "Electropop", "Art Pop", "Dream Pop", "Baroque Pop", "Hyperpop", "Europop", "Sophisti-pop", "Jangle Pop", "Chamber Pop"],
  "R&B": ["Contemporary R&B", "Neo Soul", "Quiet Storm", "Funk Soul", "Blue-Eyed Soul", "Alternative R&B", "New Jack Swing", "Rhythm and Blues"],
  "Country": ["Bluegrass", "Country Pop", "Alternative Country", "Honky Tonk", "Outlaw Country", "Americana", "Country Rock", "Neotraditional Country", "Bakersfield Sound", "Red Dirt"],
  "Blues": ["Delta Blues", "Chicago Blues", "Electric Blues", "Blues Rock", "Piedmont Blues", "Jump Blues", "Texas Blues", "British Blues", "Soul Blues", "Swamp Blues"],
  "Funk": ["P-Funk", "Funk Rock", "Afrobeat", "Go-go", "Funk Metal", "Electro-Funk", "Boogie", "Minneapolis Sound", "Nu-Funk", "Funk Carioca"],
  "Soul": ["Motown", "Northern Soul", "Southern Soul", "Philly Soul", "Psychedelic Soul", "Blue-Eyed Soul", "Deep Soul", "Memphis Soul", "Chicago Soul", "Modern Soul"],
  "Reggae": ["Dancehall", "Dub", "Roots Reggae", "Lovers Rock", "Reggaeton", "Ska", "Rocksteady", "Raggamuffin", "Nyabinghi", "Soca"],
  "Metal": ["Heavy Metal", "Thrash Metal", "Black Metal", "Doom Metal", "Death Metal", "Nu Metal", "Power Metal", "Symphonic Metal", "Groove Metal", "Sludge Metal", "Djent", "Metalcore"],
  "Indie": ["Indie Pop", "Indie Rock", "Indie Folk", "Indie Electronica", "Indie Dance", "Lo-fi Indie", "Bedroom Pop", "C86", "Indietronica", "Twee Pop"],
  "Punjabi": ["Bhangra", "Punjabi Pop", "Punjabi Folk", "Punjabi Rap", "Sufi", "Dhol", "Tumbi", "Jugni"],
  "Bollywood": ["Punjabi Pop", "Indi-Pop", "Filmi", "Qawwali", "Ghazal", "Item Number"],
  "Desi Hip Hop": ["Punjabi", "Hip Hop", "Gully Rap", "Desi Trap", "Haryanvi Hip Hop"],
  "Latin": ["Reggaeton", "Salsa", "Bachata", "Tango", "Merengue", "Cumbia", "Bossa Nova", "Latin Trap", "Dembow", "Vallenato", "Mariachi", "Norteño"],
  "World": ["Afro-Cuban", "Afrobeat", "Klezmer", "Soca", "Zouk", "Raï", "Highlife", "Fado", "Gamelan", "Balkan Beat", "Gypsy Jazz", "Tarantella"],
  "Folk": ["Contemporary Folk", "Folk Rock", "Celtic", "Appalachian", "Nordic Folk", "Folk Metal", "Anti-Folk", "Freak Folk", "Neofolk", "Folktronica"],
  "Classical": ["Baroque", "Romantic", "Contemporary Classical", "Minimalism", "Avant-Garde", "Neoclassical", "Impressionist", "Serialist", "Spectral", "Microtonal"],
  "Gospel": ["Spiritual", "Christian Rock", "Christian Hip Hop", "Southern Gospel", "Contemporary Christian", "Praise & Worship", "Black Gospel", "Christian Metal"],
  "J-Pop": ["K-Pop", "C-Pop", "J-Rock", "K-Rock", "Enka", "Anison", "Idol Pop", "Visual Kei", "Vocaloid", "City Pop"],
  "New Age": ["Meditative", "Healing", "Ambient", "Chillout", "Downtempo", "Space Music", "Nature Sounds", "Shamanic", "Binaural Beats", "Neoclassical New Age"],
  "Experimental": ["Noise", "Drone", "Avant-Garde", "Industrial", "Musique Concrète", "Sound Collage", "Free Improvisation", "Plunderphonics", "Harsh Noise Wall", "Lowercase"],
  "Traditional": ["Tuvan Throat Singing", "Flamenco", "Carnatic", "Qawwali", "Mariachi", "Taiko", "Samba", "Gypsy", "Celtic", "Gnawa"],
  "Drum and Bass": ["Neurofunk", "Liquid Funk", "Jump Up", "Techstep", "Darkstep", "Atmospheric DnB", "Intelligent Drum and Bass", "Sambass", "Drumstep", "Jazzstep"],
  "Garage": ["UK Garage", "Future Garage", "Speed Garage", "2-Step", "Bassline", "Grime", "Dubstep", "UK Funky", "Breakstep", "Grindie"],
  "Psychedelic": ["Psytrance", "Goa Trance", "Psychedelic Rock", "Acid House", "Space Rock", "Stoner Rock", "Neo-Psychedelia", "Psychedelic Folk", "Acid Jazz", "Psychedelic Soul"],
  "Post-Metal": ["Sludge Metal", "Drone Metal", "Blackgaze", "Atmospheric Sludge Metal", "Post-Hardcore", "Mathcore", "Shoegaze Metal", "Avant-Garde Metal"],
  "Math Rock": ["Post-Rock", "Midwest Emo", "Post-Hardcore", "Progressive Rock", "Experimental Rock", "Art Rock", "Mathcore", "Technical Death Metal"],
  "Industrial": ["EBM", "Industrial Metal", "Aggrotech", "Power Electronics", "Futurepop", "Neue Deutsche Härte", "Coldwave", "Dark Electro", "Industrial Rock", "Rhythmic Noise"],
  "Synthwave": ["Outrun", "Retrowave", "Darksynth", "Cyberpunk", "Vaporwave", "Future Funk", "Chillwave", "Dreamwave", "Synthpop", "New Retro Wave"]
};