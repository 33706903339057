import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-out;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  text-align: center;
`;

const Spinner = styled.div`
  border: 5px solid #f3f3f3;
  border-top: 5px solid #f3b718;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
`;

const LoadingText = styled.p`
  color: #ffffff;
  font-size: 1.5rem;
  margin-top: 20px;
  font-weight: bold;
`;

const ProgressBar = styled.div`
  width: 300px;
  height: 10px;
  background-color: #333;
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
`;

const Progress = styled.div`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: #f3b718;
  transition: width 0.5s ease-out;
`;

const Tip = styled.p`
  color: #f3b718;
  font-size: 1rem;
  margin-top: 30px;
  max-width: 400px;
  animation: ${pulse} 2s infinite ease-in-out;
`;

const tips = [
  "Did you know? Our AI can generate music in any genre!",
  "Try combining different styles for unique results!",
  "You can download your tracks in high quality after generation.",
  "Experiment with different prompts to fine-tune your results.",
  "Our AI learns from millions of songs to create original music.",
];

const LoadingOverlay = ({ onComplete }) => {
  const [progress, setProgress] = useState(0);
  const [tip, setTip] = useState(tips[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          onComplete(); // Call the onComplete function when loading is complete
          return 100;
        }
        return prevProgress + 1;
      });
    }, 100);

    return () => clearInterval(interval);
  }, [onComplete]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTip(prevTip => {
        const currentIndex = tips.indexOf(prevTip);
        return tips[(currentIndex + 1) % tips.length];
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Overlay>
      <Content>
        <Spinner />
        <LoadingText>Crafting Your Unique Track...</LoadingText>
        <ProgressBar>
          <Progress progress={progress} />
        </ProgressBar>
        <Tip>{tip}</Tip>
      </Content>
    </Overlay>
  );
};

LoadingOverlay.defaultProps = {
  onComplete: () => {}, // Default to a no-op function
};

export default LoadingOverlay;
