import React, { useState, useEffect, useRef , useImperativeHandle} from "react";
import {
  Play,
  Pause,
  Volume2,
  VolumeX,
  Loader,
  SkipForward,
  SkipBack,
  Shuffle,
  Repeat,
  RefreshCw,
} from "lucide-react";
import styled, { keyframes, css } from "styled-components";
import ReactH5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const rotateAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const scrollAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-100% + 150px)); }
`;

const PlayerContainer = styled.div`
  width: 100%;
  padding: 10px;
  background: ${props => props.colors.background};
  color: ${props => props.colors.text};
  font-family: 'Roboto', 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @media (min-width: 768px) {
    flex-direction: row;
    height: 80px;
  }
`;

const TrackInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    width: 30%;
    margin-bottom: 0;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    width: 40%;
  }
`;

const VolumeControl = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    width: 30%;
    justify-content: flex-end;
  }
`;

const AnimatedButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.color};
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    animation: ${pulseAnimation} 1s infinite;
    color: ${props => props.hoverColor || props.color};
  }
`;

const LoadingSpinner = styled(Loader)`
  animation: ${rotateAnimation} 2s linear infinite;
`;

const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.7em;
  color: ${props => props.color};
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
`;

const TagsScroller = styled.div`
  display: inline-block;
  transition: all 0.3s ease-out;
  padding-right: 20px;

  ${props => props.isAnimating && css`
    animation: ${scrollAnimation} ${props.duration}s linear infinite;
  `}
`;

const ProgressBar = styled.div`
  flex: 1;
  height: 4px;
  background: ${props => `${props.colors.primary}33`};
  border-radius: 2px;
  cursor: pointer;
  position: relative;
`;

const ProgressFill = styled.div`
  width: ${props => props.width}%;
  height: 100%;
  background: ${props => props.colors.primary};
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.1s linear;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 10px;
`;

const RetryButton = styled.button`
  background-color: ${props => props.colors.primary};
  color: ${props => props.colors.background};
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomAudioPlayer = styled(ReactH5AudioPlayer)`

  .rhap_play_pause_button,
  .rhap_volume_button,
  .rhap_time,
  .rhap_progress_played {
    color: #f3b718 !important;
  }

  .rhap_play_pause_button,
  .rhap_volume_button {
    background-color: #000000 !important;
    border: 1px solid #f3b718 !important;
  }
 .rhap_progress-filled {
   background-color: #f3b718 !important;
 }

  .rhap_progress_played {
    background-color: #f3b718 !important;
  }

  .rhap_skip_button,
  .rhap_jump_button {
    display: none !important;
  }
    .rhap_stacked .rhap_controls-section{
    margin-top:0;
    }
    .rhap_play-pause-button{
    color : #f3b718 !important;
    }
    .rhap_volume-bar{
    background-color: #f3b718 !important;
    }

`;

const AudioPlayerHTML5 = ({
  audioPlayerRef,
  currentTrack,
  handleNext,
  handlePrev,
  isPlaying,
  setIsPlaying,
  newTracks,
  setNewTracks,
  shuffleTrack
}) => {
  const audioRef = useRef(null);
  const tagsRef = useRef(null);
  const [isTagsHovered, setIsTagsHovered] = useState(false);
  const [shuffleActive, setShuffleActive] = useState(false);
  const [repeatActive, setRepeatActive] = useState(false);

  // Brand colors
  const colors = {
    primary: "#f3b718",
    background: "#000000",
    text: "#ffffff",
  };


  useEffect(() => {
    const checkAndStylePlayer = () => {
      const elem = document.querySelector('.rhap_container');
      if (elem) {
        elem.style.backgroundColor = '#000';
        elem.style.padding = "6px 64px";
        elem.style.height = "3.5rem"
        if(window.innerWidth >= 1030){
         elem.style.position="absolute"
        elem.style.left = "50%"
        elem.style.transform = "translate(-50%)"
        elem.style.width = "50rem"
       }
       if(window.innerWidth <= 600){
         const volBtn =  document.querySelector(".rhap_volume-controls")
        volBtn.style.visibility="hidden"
        volBtn.style.pointerEvents = "none"
       }
      } else {
        setTimeout(checkAndStylePlayer, 100);
      }
    };

    checkAndStylePlayer();
  }, []);

  const truncateTags = (tags, maxLength = 20) => {
    if (!tags) return "No tags";
    let tagsString = "";
    if (Array.isArray(tags)) {
      tagsString = tags.join(", ");
    } else if (typeof tags === "string") {
      tagsString = tags;
    } else {
      return "No tags";
    }
    return isTagsHovered ? tagsString : (tagsString.length > maxLength ? tagsString.slice(0, maxLength) + "..." : tagsString);
  };

  const getTagsArray = (tags) => {
    if (!tags) return [];
    if (Array.isArray(tags)) return tags;
    if (typeof tags === "string") return tags.split(",").map(tag => tag.trim());
    return [];
  };

  const calculateAnimationDuration = (tags) => {
    const tagsArray = getTagsArray(tags);
    const totalLength = tagsArray.join(", ").length;
    return Math.max(totalLength * 0.1, 3);
  };

  const handleRepeat = () => {
    setRepeatActive(!repeatActive);
  }

 const handleShuffle = () => {
 setShuffleActive(!shuffleActive);
 }

 const handleEnd = () => {

  if(!shuffleActive && !repeatActive){
    handleNext()
    return
  }

  if(shuffleActive){
    shuffleTrack()
  }else if(repeatActive){
    audioPlayerRef.current.audio.current.play()
  }
 }



  return (
    <PlayerContainer colors={colors}>
      <TrackInfo>
        <img
          src={currentTrack?.imageUrl || currentTrack?.image_url || "/logo512.png"}
          alt={currentTrack?.title || "Untitled" || "Default cover"}
          style={{
            width: "40px",
            height: "40px",
            marginRight: "10px",
            borderRadius: "4px",
          }}
        />
        <div style={{ overflow: "hidden" }}>
          <div style={{
            fontWeight: "bold",
            fontSize: "0.9em",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
            {currentTrack?.title || "Untitled" || "Select a track to begin"}
          </div>
          <TagsContainer
            color={colors.primary}
            ref={tagsRef}
            onMouseEnter={() => setIsTagsHovered(true)}
            onMouseLeave={() => setIsTagsHovered(false)}
          >
            <TagsScroller
              duration={calculateAnimationDuration(currentTrack?.tags)}
              isAnimating={isTagsHovered}
            >
              {truncateTags(currentTrack?.tags)}
            </TagsScroller>
          </TagsContainer>
        </div>
      </TrackInfo>

      <CustomAudioPlayer
          ref={audioPlayerRef}
          src={localStorage.getItem(`cdn_${currentTrack?.id}`) ? currentTrack?.cdn_url : currentTrack?.temp_url}
          onPlay={() => {
            !isPlaying && setIsPlaying(true)
            newTracks.includes(currentTrack?.id) && setNewTracks(newTracks.filter((id) => id !== currentTrack?.id))
          }}
          onPause={() => {isPlaying && setIsPlaying(false)}}
          autoPlayAfterSrcChange={true}
          autoPlay={false}
          showJumpControls={false}
          showSkipControls={true}
          onClickNext={handleNext}
          onClickPrevious={handlePrev}
          onEnded={handleEnd}
          customAdditionalControls={[
            <button
              key="custom-button"
              onClick={handleShuffle}
              style={{
                color: `${shuffleActive ? "#f3b718" : "#fff"}`,
                cursor: 'pointer',
                margin : "0 10px"
              }}
            >
             <Shuffle size={shuffleActive ? 25 : 20}/>
            </button>,
            <button
            key="custom-button"
            onClick={handleRepeat}
            style={{
              color: `${repeatActive ? "#f3b718" : "#fff"}`,
              cursor: 'pointer',
              margin : "0 10px"
            }}
          >
           <Repeat size={repeatActive ? 25 : 20}/>
          </button>
          ]}
        />


      <audio ref={audioRef} />
    </PlayerContainer>
  );
};

export default AudioPlayerHTML5;