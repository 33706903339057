import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

const ReferPage = ({ userId }) => {
  const [isCopied, setIsCopied] = useState(false);
  const referralLink = `https://www.samplegenerator.xyz/refer/${userId}`;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(referralLink);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <Container>
      <Card>
        <Title>Refer & Earn</Title>
        <ContentWrapper>
          <IconWrapper>
            <StarIcon />
          </IconWrapper>
          <TextContent>
            <Heading>Boost Your Credits!</Heading>
            <Description>
              Share the power of our audio generation tool and earn rewards. For every friend who joins and makes a purchase, you'll receive bonus credits.
            </Description>
            <RewardInfo>
              <RewardItem>
                <RewardValue>50 Credits</RewardValue>
                <RewardDescription>When your referral purchases any package</RewardDescription>
              </RewardItem>
              <RewardItem>
                <RewardValue>100 Credits</RewardValue>
                <RewardDescription>For Premium package purchases</RewardDescription>
              </RewardItem>
            </RewardInfo>
          </TextContent>
        </ContentWrapper>
      </Card>
      <ReferralLinkSection>
        <LinkInputWrapper>
          <LinkInput value={referralLink} readOnly />
          <CopyButton onClick={handleCopy}>
            {isCopied ? 'Copied!' : 'Copy Link'}
          </CopyButton>
        </LinkInputWrapper>
      </ReferralLinkSection>
    </Container>
  );
};

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  max-width: 800px;
  margin: 60px auto;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #fff;
  animation: ${fadeIn} 0.5s ease-out;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Card = styled.div`
  background-color: #000;
  border-radius: 16px;
  padding: 18px 30px;
  margin-bottom: 30px;
  box-shadow: 0 10px 20px rgba(243, 183, 24, 0.15);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(243, 183, 24, 0.2);
  }

  @media (max-width: 768px) {
    padding: 30px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 30px;
  color: #f3b718;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
`;

const starAnimation = keyframes`
  0% { transform: scale(1) rotate(0deg); }
  50% { transform: scale(1.1) rotate(180deg); }
  100% { transform: scale(1) rotate(360deg); }
`;

const StarIcon = styled.div`
  width: 80px;
  height: 80px;
  background-color: #f3b718;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  animation: ${starAnimation} 4s infinite linear;

  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

const TextContent = styled.div`
  flex-grow: 1;
`;

const Heading = styled.h2`
  font-size: 28px;
  margin-bottom: 20px;
  color: #f3b718;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 24px;
    text-align: center;
  }
`;

const Description = styled.p`
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 1.6;
  color: #e0e0e0;

  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center;
  }
`;

const RewardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const RewardItem = styled.div`
  background-color: rgba(243, 183, 24, 0.1);
  padding: 10px;
  border-radius: 12px;
  flex: 1;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(243, 183, 24, 0.2);
  }
`;

const RewardValue = styled.h3`
  font-size: 24px;
  color: #f3b718;
  margin-bottom: 10px;
`;

const RewardDescription = styled.p`
  font-size: 16px;
  color: #e0e0e0;
`;

const ReferralLinkSection = styled.div`
  margin-bottom: 30px;
`;

const LinkText = styled.p`
  font-size: 18px;
  margin-bottom: 15px;
  color: #fff;
  text-align: center;
`;

const LinkInputWrapper = styled.div`
  display: flex;
  box-shadow: 0 5px 15px rgba(243, 183, 24, 0.1);
  border-radius: 12px;
  overflow: hidden;

  @media (max-width: 768px) {
  margin-bottom:100px;
    flex-direction: column;
  }
`;

const LinkInput = styled.input`
  flex-grow: 1;
  padding: 15px 20px;
  border: none;
  font-size: 16px;
  outline: none;
  background-color: #121212;
  color: #fff;

  @media (max-width: 768px) {
    text-align: center;
    border-bottom: 1px solid rgba(243, 183, 24, 0.2);
  }
`;

const CopyButton = styled.button`
  background-color: #f3b718;
  color: #000;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    background-color: #e0a800;
  }

  @media (max-width: 768px) {
    padding: 12px 20px;
  }
`;

export default ReferPage;