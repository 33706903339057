import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../firebase";
import LoadingOverlay from "./LoadingOverlay";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #000000;
  color: #ffffff;
  font-family: "Roboto", "Arial", sans-serif;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 100%;
  background-color: rgba(26, 26, 26, 0.8);
  border-radius: 10px;
  padding: 20px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  margin-top: 25px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.3rem;
  margin-bottom: 30px;
  text-align: center;
  color: #f3b718;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const Button = styled.button`
  padding: 12px 24px;
  font-size: 1.1rem;
  background-color: #f3b718;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 300px;

  &:hover {
    background-color: #d9a116;
  }

  &:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 10px 20px;
  }
`;

const Input = styled.input`
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
  border: 1px solid #f3b718;
  border-radius: 5px;
  background-color: #1a1a1a;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 30px 0;
  text-align: center;
  width: 100%;
  max-width: 300px;
`;

const FeatureItem = styled.li`
  font-size: 1rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Message = styled.div`
  margin-top: 20px;
  padding: 10px;
  background-color: rgba(243, 183, 24, 0.2);
  border-radius: 5px;
  text-align: center;
  font-size: 1rem;
  color: #f3b718;
  width: 100%;
  max-width: 300px;
`;

const LandingPage = ({
  onGenerateTrack,
  credits,
  setCredits,
  setIsAlert,
  setAlertContent,
  isLoading,
  setShowTutorial,
  isLoggedIn,
}) => {
  const [musicStyle, setMusicStyle] = useState("");
  const [generatedTrackId, setGeneratedTrackId] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [blockGenerate, setBlockGenerate] = useState(false);
  const navigate = useNavigate();
  const { referralId } = useParams();

  useEffect(() => {
    const firstGen = localStorage.getItem("firstGen");
    if (firstGen !== null) {
      if (firstGen) {
        setBlockGenerate(true);
      }
    }
  }, []);

  useEffect(() => {
    if (referralId) {
      if (isLoggedIn) {
        navigate("/");
      }else{
        localStorage.setItem("referralId" , referralId);
      }
    }
  }, [referralId, navigate, isLoggedIn]);

  const handleGenerateDemo = async () => {
    if (credits > 0) {
      try {
        setShowOverlay(true);
        const trackId = await onGenerateTrack(musicStyle);
        setGeneratedTrackId(trackId);
        setCredits(credits - 1);
        setIsAlert(true);
        setAlertContent({
          title: "Track Generated",
          message:
            "Your track has been generated! Sign up to listen and get 5 more free credits!",
        });
        setShowOverlay(false);
        localStorage.setItem("firstGen", true);
        setBlockGenerate(true);
      } catch (error) {
        setShowOverlay(false);
        console.error("Error generating demo track:", error);
        setIsAlert(true);
        setAlertContent({
          title: "Generation Error",
          message:
            "There was an error generating your track. Please try again.",
        });
      }
    } else {
      setIsAlert(true);
      setAlertContent({
        title: "No Credits",
        message:
          "You've used your free generation. Sign up to get 5 more free credits!",
      });
    }
  };

  const handleSignUp = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate("/");
    } catch (error) {
      console.error("Error signing up:", error);
      setIsAlert(true);
      setAlertContent({
        title: "Sign Up Error",
        message: "There was an error signing up. Please try again.",
      });
    }
  };

  return (
    <div>
      <Container>
        <Content>
          <Title>Welcome!</Title>
          <Subtitle>Create custom AI-generated music in seconds!</Subtitle>
          <Input
            type="text"
            placeholder="Enter a music style (e.g., jazz, pop, classical)"
            value={musicStyle}
            onChange={(e) => setMusicStyle(e.target.value)}
          />
          <Button onClick={handleGenerateDemo} disabled={blockGenerate}>
            {isLoading ? "Generating..." : "Generate Demo Track"}
          </Button>
          {showOverlay && <LoadingOverlay />}
          {generatedTrackId && (
            <Message>
              Your track is ready! Sign up to listen and get 5 more free
              credits!
            </Message>
          )}
          <FeatureList>
            <FeatureItem>✨ AI-powered music generation</FeatureItem>
            <FeatureItem>🎵 Multiple genres and styles</FeatureItem>
            <FeatureItem>🔊 High-quality audio output</FeatureItem>
            <FeatureItem>🚀 Fast and easy to use</FeatureItem>
          </FeatureList>
          <Button onClick={handleSignUp}>Sign Up for 5 Free Credits</Button>
        </Content>
      </Container>
    </div>
  );
};

export default LandingPage;
