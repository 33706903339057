import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  X,
  Play,
  Pause,
  Volume2,
  VolumeX,
  ChevronLeft,
  Check,
  Download,
  HelpCircle,
} from "lucide-react";
import styled, { keyframes } from "styled-components";
import TrackWaveform from "./TrackWaveForm";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { ID3Writer } from "browser-id3-writer";
import Onboarding from "./Onboarding";

const fadeInOut = keyframes`
  0%, 100% { opacity: 0.3; }
  50% { opacity: 0.7; }
`;

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(10, 10, 10, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
`;

const ModalContent = styled.div`
  background: rgba(10, 10, 10, 0.9);
  border-radius: 8px;
  padding: 24px;
  width: 95%;
  max-width: 1000px;
  height: 85vh;
  color: #e0e0e0;
  font-family: "Roboto", "Arial", sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

const BackButton = styled.button`
  position: absolute;
  top: 24px;
  left: 24px;
  background: none;
  border: none;
  color: #f3b718;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  color: #f3b718;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;

  &:hover {
    color: #ffffff;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #f3b718;
  margin: 24px 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StemList = styled.div`
  width: 250px;
  overflow-y: auto;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  padding-right: 16px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f3b718;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 16px;
  }
`;

const StemItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  ${(props) =>
    props.selected &&
    `
    background-color: rgba(243, 183, 24, 0.2);
  `}
`;

const StemIcon = styled.span`
  margin-right: 8px;
  font-size: 20px;
`;

const StemName = styled.span`
  flex-grow: 1;
`;

const MainContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const WaveformContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  height: 200px;
`;

const InfoText = styled.p`
  text-align: center;
  margin-bottom: 16px;
  font-size: 14px;
  color: #888888;
`;

const Button = styled.button`
  width: 100%;
  background-color: #f3b718;
  color: #000000;
  padding: 12px;
  border-radius: 8px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 12px;

  &:hover {
    background-color: #ffc93c;
  }

  &:disabled {
    background-color: #4a4a4a;
    color: #8a8a8a;
    cursor: not-allowed;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 16px;
`;

const ProgressFill = styled.div`
  height: 100%;
  background-color: #f3b718;
  transition: width 0.5s ease-out;
`;

const ErrorMessage = styled.div`
  color: #ff6b6b;
  text-align: center;
  margin-bottom: 16px;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #f3b718;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ComparisonItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: column;
`;

const OnboardingOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const OnboardingContent = styled.div`
  background-color: #1a1a1a;
  padding: 24px;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
`;

const OnboardingStep = styled.p`
  margin-bottom: 16px;
  font-size: 18px;
  color: #e0e0e0;
`;

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = (seconds % 60).toFixed(2);
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.padStart(
    5,
    "0"
  )}`;
};

const StemExtractionModal = ({
  isOpen,
  onClose,
  track,
  userCredits,
  getStemExtraction,
  setUserCredits,
}) => {
  const [step, setStep] = useState(1);
  const [selectedStem, setSelectedStem] = useState([]);
  const [progress, setProgress] = useState(0);
  const [extractedStems, setExtractedStems] = useState([]);
  const [isPreviewMode, setIsPreviewMode] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [creditsReserved, setCreditsReserved] = useState(0);

  const progressIntervalRef = useRef(null);

  const stems = [
    { name: "Vocal and Instrumental", icon: "🎤", value: "vocals" },
    { name: "Voice and Noise", icon: "🗣️", value: "voice" },
    { name: "Drums", icon: "🥁", value: "drum" },
    { name: "Piano", icon: "🎹", value: "piano" },
    { name: "Bass", icon: "🎸", value: "bass" },
    { name: "Electric guitar", icon: "🎸", value: "electric_guitar" },
    { name: "Acoustic guitar", icon: "🪕", value: "acoustic_guitar" },
    { name: "Synthesizer", icon: "🎹", value: "synthesizer" },
    { name: "Strings", icon: "🎻", value: "strings" },
    { name: "Wind", icon: "🎷", value: "wind" },
  ];

  const startProgressSimulation = useCallback(() => {
    let progress = 0;
    progressIntervalRef.current = setInterval(() => {
      progress += 2;
      if (progress >= 95) {
        clearInterval(progressIntervalRef.current);
      } else {
        setProgress(progress);
      }
    }, 600);
  }, []);

  useEffect(() => {
    function getAudioDuration(fileUrl) {
      return new Promise((resolve, reject) => {
        const audio = new Audio();

        audio.addEventListener("loadedmetadata", () => {
          // Audio duration is available in seconds
          resolve(audio.duration);
        });

        audio.addEventListener("error", (error) => {
          reject(new Error(`Error loading audio file: ${error.message}`));
        });

        audio.src = fileUrl;
      });
    }

    const updateTrackDuration = async () => {
      const duration = await getAudioDuration(track.cdn_url);
      setDuration(duration);
    };

    updateTrackDuration();
  }, [track]);

  // const fetchAudioBlob = useCallback(async (audioUrl) => {
  //   try {
  //     const response = await fetch(audioUrl);
  //     if (!response.ok) throw new Error("Failed to fetch audio");
  //     const audioBlob = await response.blob();
  //     return audioBlob;
  //   } catch (error) {
  //     console.error("Error fetching audio blob:", error);
  //     setError("Failed to fetch audio. Please try again.");
  //   }
  // }, []);

  useEffect(() => {
    if (step === 2) {
      startProgressSimulation();
      const extractStems = async () => {
        try {
          const extractionResults = await getStemExtraction(
            track.cdn_url,
            [{ id: "", stem: selectedStem }],
            isPreviewMode ? "preview" : "full"
          );

          clearInterval(progressIntervalRef.current);

          if (
            extractionResults &&
            extractionResults.split &&
            extractionResults.split.stem_track &&
            extractionResults.split.back_track
          ) {
            const processedResults = [
              {
                name: selectedStem,
                stemUrl: extractionResults.split.stem_track,
                backingUrl: extractionResults.split.back_track,
              },
            ];

            setStep(3);
            setExtractedStems(processedResults);
          } else {
            throw new Error("Invalid extraction results");
          }
        } catch (err) {
          console.error("Stem extraction error:", err);
          clearInterval(progressIntervalRef.current);
          setError("Failed to extract stems. Please try again.");
          setUserCredits((prevCredits) => prevCredits + creditsReserved);
          setCreditsReserved(0);
          setStep(1);
        }
      };
      extractStems();
    }
  }, [
    step,
    selectedStem,
    track.cdn_url,
    getStemExtraction,
    isPreviewMode,
    startProgressSimulation,
    setUserCredits,
    creditsReserved,
  ]);

  const handleStemSelect = useCallback((stem) => {
    setSelectedStem(stem);
    setStep(1);
  }, []);

  const handleExtraction = useCallback(() => {
    const requiredCredits = isPreviewMode ? 1 : 30;
    if (userCredits >= requiredCredits) {
      setCreditsReserved(requiredCredits);
      setUserCredits((prevCredits) => prevCredits - requiredCredits);
      setStep(2);
    } else {
      setError("Not enough credits. Please purchase more.");
    }
  }, [isPreviewMode, userCredits, setUserCredits]);

  const handleSplitInFull = useCallback(() => {
    setIsPreviewMode(false);
    setStep(1);
  }, []);

  const handleStemDownload = useCallback(
    async (stem) => {
      if (isPreviewMode) {
        setError(
          "You can't download preview stems. Please extract the full track."
        );
        return;
      }

      setIsDownloading(true);

      try {
        const zip = new JSZip();

        const fetchAndModifyAudio = async (url, filename) => {
          const response = await fetch(url);
          const arrayBuffer = await response.arrayBuffer();

          const writer = new ID3Writer(arrayBuffer);

          writer
            .setFrame("TIT2", filename)
            .setFrame("TALB", "SampleGenerator.xyz")
            .setFrame("TPE1", ["SampleGenerator"])
            .setFrame("TCON", ["AI Generated"]);

          writer.addTag();

          return new Blob([writer.arrayBuffer], { type: "audio/mpeg" });
        };

        const stemBlob = await fetchAndModifyAudio(
          stem.stemUrl,
          `${track.title}_${stem.name}_stem`
        );
        zip.file(
          `SampleGenerator_${track.title}_${stem.name}_stem.mp3`,
          stemBlob
        );

        const backingBlob = await fetchAndModifyAudio(
          stem.backingUrl,
          `${track.title}_${stem.name}_backing`
        );
        zip.file(
          `SampleGenerator_${track.title}_${stem.name}_backing.mp3`,
          backingBlob
        );

        const content = await zip.generateAsync({ type: "blob" });
        saveAs(
          content,
          `SampleGenerator_${track.title}_${stem.name}_stems.zip`
        );
      } catch (error) {
        console.error("Error creating zip file:", error);
        setError(
          "An error occurred while creating the download. Please try again."
        );
      } finally {
        setIsDownloading(false);
      }
    },
    [isPreviewMode, track.title]
  );

  const handleOnboardingNext = useCallback(() => {
    if (onboardingStep < 3) {
      setOnboardingStep((prev) => prev + 1);
    } else {
      setShowOnboarding(false);
    }
  }, [onboardingStep]);

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        {step > 1 && (
          <BackButton onClick={() => setStep((prev) => prev - 1)}>
            <ChevronLeft size={20} />
            Back
          </BackButton>
        )}
        <CloseButton onClick={onClose}>
          <X size={24} />
        </CloseButton>
        <Title>
          {step === 1 && "Select stems to extract"}
          {step === 2 &&
            (isPreviewMode ? "Generating previews..." : "Extracting stems...")}
          {step === 3 && isLoaded && "Your previews are ready!"}
          {step === 3 &&
            !isLoaded &&
            `Loading ${isPreviewMode ? "Preview..." : "Stems..."}`}
        </Title>
        <ContentWrapper>
          <StemList>
            {stems.map((stem) => (
              <StemItem
                className="StemItem"
                key={stem.name}
                selected={selectedStem.includes(stem.value)}
                onClick={() => handleStemSelect(stem.value)}
              >
                <StemIcon>{stem.icon}</StemIcon>
                <StemName>{stem.name}</StemName>
                {selectedStem.includes(stem.value) && (
                  <Check size={16} color="#f3b718" />
                )}
              </StemItem>
            ))}
          </StemList>
          <MainContent>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {step === 1 && (
              <>
                <InfoText>
                  Track duration:{" "}
                  {duration ? formatTime(duration) : "Calculating..."}
                </InfoText>
                <InfoText>
                  Cost:{" "}
                  {isPreviewMode
                    ? "Preview (1 Credit)"
                    : "Full Extraction (30 credits)"}
                </InfoText>
                <InfoText>Your credits: {userCredits}</InfoText>
                <Button
                  onClick={handleExtraction}
                  disabled={selectedStem.length === 0}
                >
                  {isPreviewMode ? "Generate Previews" : "Extract Stems"}
                </Button>
                <Button
                  className="stemButton"
                  onClick={() => setIsPreviewMode((prev) => !prev)}
                >
                  Switch to {isPreviewMode ? "Full Extraction" : "Preview Mode"}
                </Button>
                <Button onClick={() => setShowOnboarding(true)}>
                  Show Onboarding
                </Button>
              </>
            )}
            {step === 2 && (
              <>
                <ProgressBar>
                  <ProgressFill style={{ width: `${progress}%` }} />
                </ProgressBar>
                <InfoText>{progress}% Complete</InfoText>
                <InfoText>
                  Estimated time: {Math.ceil((100 - progress) / 10)} seconds
                </InfoText>
              </>
            )}
            {step === 3 && !isLoaded && (
              <LoadingOverlay>
                <LoadingSpinner />
              </LoadingOverlay>
            )}
            {step === 3 && (
              <>
                <ComparisonContainer>
                  {extractedStems.map((stem, index) => (
                    <ComparisonItem key={index}>
                      <div>
                        <TrackWaveform
                          trackUrls={[stem.stemUrl, stem.backingUrl]}
                          setIsLoaded={setIsLoaded}
                          stem={selectedStem}
                          duration={duration}
                        />
                      </div>
                    </ComparisonItem>
                  ))}
                </ComparisonContainer>
                {isPreviewMode ? (
                  <Button onClick={handleSplitInFull}>Split in Full</Button>
                ) : (
                  <>
                    {extractedStems.map((stem) => (
                      <Button
                        key={stem.name}
                        onClick={() => handleStemDownload(stem)}
                        disabled={isDownloading}
                      >
                        {isDownloading ? (
                          <div>Downloading...</div>
                        ) : (
                          <>
                            <Download
                              size={16}
                              style={{ marginRight: "8px" }}
                            />
                            Download {stem.name} (ZIP)
                          </>
                        )}
                      </Button>
                    ))}
                    <Button onClick={() => setStep(1)}>
                      Extract More Stems
                    </Button>
                  </>
                )}
              </>
            )}
          </MainContent>
        </ContentWrapper>
      </ModalContent>

      {showOnboarding && (
        <Onboarding
          step={onboardingStep}
          setStep={setOnboardingStep}
          onComplete={() => {
            setShowOnboarding(false);
          }}
        />
      )}

      <Tooltip id="stem-select-tooltip" />
      <Tooltip id="preview-mode-tooltip" />
      <Tooltip id="full-extraction-tooltip" />
      <Tooltip id="download-tooltip" />
    </ModalOverlay>
  );
};

export default StemExtractionModal;
