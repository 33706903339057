// src/components/SuccessPage.js
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, updateDoc, getDoc, increment } from "firebase/firestore";
import { db, auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

const SuccessPage = () => {
  const navigate = useNavigate();
  const [paymentDone, setPaymentDone] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const sessionId = query.get("session_id");

  useEffect(() => {
    if (!paymentDone) {
      const updateFirebase = async () => {
        // Check if the user is authenticated
        const user = auth.currentUser;
        if (!user) {
          console.error("User is not authenticated");
          window.location.replace("/");
          return;
        }

        try {
          const sessionResponse = await fetch(
            "https://audio-gen-server.vercel.app/api/retrieve-session",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ session_id: sessionId }),
            }
          );
          const data = await sessionResponse.json();
          const metadata = data.metadata;

          const userId = metadata.userId;
          if (userId !== user.uid) {
            console.error("User ID mismatch");
            window.location.replace("/");
            return;
          }

          const userDocRef = doc(db, "users", userId);

          // Fetch current user document
          const userDoc = await getDoc(userDocRef);

          if (!userDoc.exists() || userDoc.data().session_id !== sessionId) {
            window.location.replace("/");
            return;
          }

          await updateDoc(userDocRef, {
            plan: metadata.planName || userDoc.data().plan,
            credits: increment(metadata.credits),
            billingPeriod:
              metadata.billingPeriod || userDoc.data().billingPeriod || "none",
            session_id: null,
            customerId: data.customer
          });

          if (userDoc.data().referralId) {
            console.log(userDoc.data() , metadata)
            const referralUserDocRef = doc(db, "users", userDoc.data().referralId);
            const referralUserDoc = await getDoc(referralUserDocRef);
            if (referralUserDoc.exists()) {
              await updateDoc(referralUserDocRef, {
                credits: increment(metadata.referralBonus),
                alert : {title : "Bonus" , message : `Congratulations! You just earned ${metadata.referralBonus} credits for referring ${userDoc.data().name} to our platform.`}
              });
            }
          }

          setPaymentDone(true);
        } catch (error) {
          console.error("Error updating Firebase:", error);
          window.location.replace("/");
        }
      };

      // Check authentication state before updating Firebase
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          updateFirebase();
        } else {
          console.error("User is not authenticated");
          window.location.replace("/");
        }
      });

      return () => unsubscribe(); // Cleanup the listener
    }
  }, [sessionId, paymentDone]);

  useEffect(() => {
    if (paymentDone) {
      const timer = setTimeout(() => {
        window.location.replace("/");
      }, 5400);

      return () => clearTimeout(timer);
    }
  }, [navigate, paymentDone]);

  return (
    <div className="bg-black text-white w-screen h-screen absolute top-0 z-[999999] flex justify-center items-center flex-col">
      {paymentDone ? (
        <>
          <h1 className="mt-40">Payment Successful!</h1>
          <p>Thank you for your purchase. Your credits have been updated.</p>
          <p>You will be redirected to the home page in a few seconds...</p>
        </>
      ) : (
        <p>Payment Processing...</p>
      )}
    </div>
  );
};

export default SuccessPage;
