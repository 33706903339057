import React, { useState, useEffect } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import ReactH5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #000000;
  color: #ffffff;
  font-family: 'Roboto', 'Arial', sans-serif;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 100%;
  background-color: rgba(26, 26, 26, 0.8);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
`;

const TrackInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const TrackImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin-right: 20px;
`;

const TrackDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const TrackTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 5px;
`;

const TrackGenre = styled.p`
  font-size: 1rem;
  color: #f3b718;
`;

const CTAButton = styled(Link)`
  padding: 12px 24px;
  font-size: 1.1rem;
  background-color: #f3b718;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  text-align: center;
  margin-top: 20px;

  &:hover {
    background-color: #d9a116;
  }
`;

const ErrorMessage = styled.div`
  color: #ff6b6b;
  text-align: center;
  margin-top: 20px;
`;

const CustomAudioPlayer = styled(ReactH5AudioPlayer)`
  .rhap_container {
    background-color: black!important;
    color: #ffffff !important;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2) !important;
  }

  .rhap_play_pause_button,
  .rhap_volume_button,
  .rhap_time,
  .rhap_progress_played {
    color: #f3b718 !important;
  }

  .rhap_play_pause_button,
  .rhap_volume_button {
    background-color: #000000 !important;
    border: 1px solid #f3b718 !important;
  }

  .rhap_progress_container {
    background-color: #f3b718 !important;
  }

  .rhap_progress_played {
    background-color: #f3b718 !important;
  }

  .rhap_skip_button,
  .rhap_jump_button {
    display: none !important;
  }
`;

const SharedTrackPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [track, setTrack] = useState(null);
  const location = useLocation();

  const { id } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const encodedData = queryParams.get('data');

  useEffect(() => {
    const checkAndStylePlayer = () => {
      const elem = document.querySelector('.rhap_container');
      if (elem) {
        elem.style.backgroundColor = '#151515';
      } else {
        setTimeout(checkAndStylePlayer, 100);
      }
    };

    checkAndStylePlayer();
  }, []);

  useEffect(() => {
    const fetchTrack = async () => {
      setIsLoading(true);
      setError(null);
      try {
        if (encodedData) {
          const decodedData = JSON.parse(atob(encodedData));
          setTrack({
            id: id,
            title: decodedData.title,
            image_url: decodedData.imageUrl,
            tags: decodedData.tags,
            fileUrl: decodedData.fileUrl,
          });
        } else {
          throw new Error('Track data is missing');
        }
      } catch (err) {
        setError('Unable to load the track. It may not exist or has been removed.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrack();
  }, [encodedData, id]);

  if (isLoading) {
    return (
      <Container>
        <Content>
          <Title>Loading track...</Title>
        </Content>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Content>
          <Title>Oops! Something went wrong</Title>
          <ErrorMessage>{error}</ErrorMessage>
          <CTAButton to="/">Explore More Music</CTAButton>
        </Content>
      </Container>
    );
  }

  if (!track) {
    return (
      <Container>
        <Content>
          <Title>Track Not Found</Title>
          <ErrorMessage>The requested track could not be found.</ErrorMessage>
          <CTAButton to="/">Discover More Tracks</CTAButton>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <Title>Listen to this awesome track!</Title>
        <TrackInfo>
          <TrackImage src={track.image_url || "/Infinity@1x-1.0s-200px-200px.svg"} alt={track.title} />
          <TrackDetails>
            <TrackTitle>{track.title || "Untitled"}</TrackTitle>
            <TrackGenre>{track.tags}</TrackGenre>
          </TrackDetails>
        </TrackInfo>
        <CustomAudioPlayer
          src={track.fileUrl}
          autoPlay={false}
          showSkipControls={false}
          showJumpControls={false}
        />
        <CTAButton to="/">Create Your Own Music</CTAButton>
      </Content>
    </Container>
  );
};

export default SharedTrackPage;